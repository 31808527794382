<template>
<div id="point" :style="{width: '100%', height: '100%',margin: '10px auto'}"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "points",
  props:{
    List:{
      type: Array,
      default:()=>[]
    }
  },
  data() {
    return {
      data: [
        [0,10,20,30,40,50,60,70,80,90,100],
        [[10, 10],
          [12.5, 3],
          [39, 27],
          [56, 40],
          [66, 50]],
        [[8.5,10],
          [33,20],
          [17.5,30],
          [47,49],
          [89,90]],
        [[1.5,8],
          [23.7,20],
          [35,39.8],
          [17,9],
          [70,90]],
      ]
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {

      let getchart = echarts.init(document.getElementById('point'));
      const data=[50.4,54.8,58.7,62.0,64.6,66.7,68.4];
      const data2 = [49.7,53.7,57.4,60.6,63.1,65.2,66.8];
      const categories2 = [0,1,2,3,4,5,6];
      var fw = [
        {
          xx:45.2,sx:55.8
        },
        {
          xx:48.7,sx:61.2
        },
        {
          xx:52.2,sx:65.7
        },
        {
          xx:55.3,sx:69.0
        },
        {
          xx:57.9,sx:71.7
        },
        {
          xx:59.9,sx:73.9
        },
        {
          xx:61.4,sx:75.8
        },
      ];
      var fw2 = [
        {
          xx:44.7,sx:55.0
        },
        {
          xx:47.9,sx:59.9
        },
        {
          xx:51.1,sx:64.1
        },
        {
          xx:54.2,sx:67.5
        },
        {
          xx:56.7,sx:70.0
        },
        {
          xx:58.6,sx:72.1
        },
        {
          xx:60.1,sx:74.0
        },
      ];
      var option = {
        title: {
          text: '婴儿身高标准表',
          textStyle: {
            fontSize: 18,//字体大小
            color: '#ffffff',//字体颜色，
            fontFamily:'qingning'
          },
        },
        legend: [
          {
            top: "0%",
            right: "22%",
            textStyle: {
              // 图例文字样式
              color: "white",
              fontSize: 12,
              fontFamily:'qingning'
            },
            // itemStyle:{
            //   // color:"#fff"
            // },
            itemWidth: 25, // 图例图形的宽度
            itemHeight: 12, // 图例图形的高度
            data: [
              {
                name: "男宝宝", // 图例文字内容
                // icon: "roundRect" // 图例图形的形状，有多个可选值：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'，
                //也可引用自定义的图片
                //  icon: "image://https://p1.pstatp.com/list/dfic-imagehandler/8ac89fbe-b30c-4ba0-8d75-02c0f60a7ea6"
              }
            ]
          },
          {
            top: "0%",
            right: "5%",
            textStyle: {
              // 图例文字样式
              color: "white",
              fontSize: 12,
              fontFamily:'qingning'
            },
            itemWidth: 25, // 图例图形的宽度
            itemHeight: 12, // 图例图形的高度
            data: [
              {
                name: "女宝宝", // 图例文字内容
                // icon: "roundRect" // 图例图形的形状，有多个可选值：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'，
                //也可引用自定义的图片
                //  icon: "image://https://p1.pstatp.com/list/dfic-imagehandler/8ac89fbe-b30c-4ba0-8d75-02c0f60a7ea6"
              }
            ]
          },
        ],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            // params = params[0];
            if (params.seriesName == '男宝宝') var result =params.marker  + ' '+params.name +  '月龄<br/>标准：'+ params.value+'kg ；<br/>范围：'+fw[params.dataIndex].xx+'~'+fw[params.dataIndex].sx+'kg'
            else result = params.marker  + ' '+params.name +  '月龄<br/>标准：'+ params.value+'kg ；<br/>范围：'+fw2[params.dataIndex].xx+'~'+fw2[params.dataIndex].sx+'kg'
            console.log(params);
            return result
          },
          // axisPointer: {
          //   animation: false
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: categories2,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff',
              // fontFamily:'qingning'
            }
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          min:40,
          max:80,
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff',
              // fontFamily:'qingning'
            }
          }
          // textStyle: {
          //   // fontSize: 18,//字体大小
          //   color: '#ffffff'//字体颜色
          // },
        },
        series: [
          {
            name: '男宝宝',
            type: 'line',
            showSymbol: true,
            data: data,
            lineStyle:{
              color:'#50c8f6'
            },
            itemStyle:{
              color:"#50c8f6"
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(27,207,231,0.71)' },
                { offset: 1, color: 'rgba(131,246,230,0.25)' }
              ])
            }
          },
          {
            name: '女宝宝',
            type: 'line',
            showSymbol: true,
            data: data2,
            lineStyle:{
              color:'#e28f2c'
            },
            itemStyle:{
              color:"#e28f2c"
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(229,173,106,0.58)' },
                { offset: 1, color: 'rgba(229,173,106,0.25)' }
              ])
            }
          }
        ]
      };
      var app = {};
      app.count = 7;
      var i = 7;
      var j = 7;
      app.data = [50.4,54.8,58.7,62.0,64.6,66.7,68.4,69.8,71.2,72.6,74.0,75.3,76.5];
      app.data2 = [49.7,53.7,57.4,60.6,63.1,65.2,66.8,68.2,69.2,71.0,72.4,73.7,75.0];
      app.fw = [
        {
          xx:45.2,sx:55.8
        },
        {
          xx:48.7,sx:61.2
        },
        {
          xx:52.2,sx:65.7
        },
        {
          xx:55.3,sx:69.0
        },
        {
          xx:57.9,sx:71.7
        },
        {
          xx:59.9,sx:73.9
        },
        {
          xx:61.4,sx:75.8
        },
        {
          xx:62.7,sx:77.4
        },
        {
          xx:63.9,sx:78.9
        },
        {
          xx:65.2,sx:80.5
        },
        {
          xx:66.4,sx:82.1
        },
        {
          xx:67.5,sx:83.6
        },
        {
          xx:68.6,sx:85.0
        }
      ];
      app.fw2 = [
        {
          xx:44.7,sx:55.0
        },
        {
          xx:47.9,sx:59.9
        },
        {
          xx:51.1,sx:64.1
        },
        {
          xx:54.2,sx:67.5
        },
        {
          xx:56.7,sx:70.0
        },
        {
          xx:58.6,sx:72.1
        },
        {
          xx:60.1,sx:74.0
        },
        {
          xx:61.3,sx:75.6
        },
        {
          xx:62.5,sx:77.3
        },
        {
          xx:63.7,sx:78.9
        },
        {
          xx:64.9,sx:80.5
        },
        {
          xx:66.1,sx:82.0
        },
        {
          xx:67.2,sx:83.4
        }
      ];
      setInterval(function () {
        if (app.count>12&&i>12) {
          // categories2 = [0,1,2,3,4,5,6]
          // categories2 = [0,1,2,3,4,5,6]
          // data=[3.32,4.51,5.68,6.70,7.45,8.00,8.41];
          // data2 = [3.21,4.20,5.21,6.13,6.83,7.36,7.77];
          app.count = 0
          i = 0
          j = 0
        }
        data2.shift();
        data2.push(app.data2[i]);
        data.shift();
        data.push(app.data[i++]);
        categories2.shift();
        categories2.push(app.count++);
        getchart.setOption({
          xAxis: [
            {
              type: 'category',
              boundaryGap: true,
              data: categories2,
              axisLabel: {
                show: true,
                textStyle: {
                  // color: '#ffffff'
                }
              }
            }
          ],
          series: [
            {
              data: data
            },{
              data:data2
            }
          ]
        });
      }, 2100);
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
