<template>
  <div class="timeline body-bgcolor">
    <!--时间线-->
    <span class="left" @click="move(0)"></span>
    <div style="display:flex;overflow: hidden;height: 100%;position: relative;">
      <div class="timeline-container" :style="'left:'+left+'px'">
        <div style="display:flex;">
          <div v-for="(item, index) in vaccine" :key="index" style="flex:1; display:flex; flex-direction:column;align-items: center">
            <div class="tipBox" :style="'opacity:'+index%2+';background-color:'+boxColor[index]">
              <p v-html="item"></p>
            </div>
            <div style="flex:1; display:flex">
              <div class="dot" style="background: #24dbf3;"></div>
              <div class="item" v-show="index != 13"></div>
            </div>
            <div class="tipBox" :style="'opacity:'+(index%2?0:1)+';background-color:'+boxColor[index]">
              <p v-html="item"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="right" @click="move(1)"></span>
  </div>
</template>

<script>
export default {
  name: "timeline",
  data() {
    return {
      timeIndex: 0,
      left:0,
      vaccine:[
          '出生时：<br/>乙型肝炎疫苗（1）（出生后24小时内接种）<br/>卡介苗',
          '1月龄：<br/>乙型肝炎疫苗（2）（与第1剂间隔>=28天）',
          '2月龄：<br/>脊髓灰质炎减毒活疫苗（1）',
          '3月龄：<br/>脊髓灰质炎减毒活疫苗（2）（与第1剂间隔>=28天）<br/>百日咳-白喉-破伤风联合疫苗（1）',
          '4月龄：<br/>脊髓灰质炎减毒活疫苗（3）（与第2剂间隔>=28天）<br/>百日咳-白喉-破伤风联合疫苗（2）（与第1剂间隔>=28天）',
          '5月龄：<br/>百日咳-白喉-破伤风联合疫苗（3）（与第2剂间隔>=28天）',
          '6月龄：<br/>乙型肝炎疫苗（3）<br/>A群脑膜炎球菌多糖疫苗（1+2）（第1、2剂间隔3个月，最晚第18月接种）',
          '8月龄：<br/>麻醉-风疹联合疫苗<br/>流行性乙型脑炎减毒活疫苗（1）<br/>乙脑灭活疫苗（1+2）(第1、2剂间隔7~10天)',
          '18月龄：<br/>甲型肝炎减毒活疫苗<br/>甲肝灭活疫苗（1）<br/>百日咳-白喉-破伤风联合疫苗（4）<br/>麻疹-流行性腮腺炎-风疹联合疫苗（最晚第24月接种）',
          '2岁：<br/>流行性乙型脑炎减毒活疫苗（2）<br/>乙脑灭活疫苗（3）<br/>甲肝灭活疫苗（2）（与第1剂间隔>=6个月,最晚第30月接种）',
          '3岁：<br/>A群C群脑膜炎球菌多糖疫苗（1）（与A群脑膜炎球菌多糖疫苗第2剂间隔>=12个月）',
          '4岁：<br/>脊髓灰质炎减毒活疫苗（4）',
          '6岁：<br/>白喉-破伤风联合疫苗<br/>A群C群脑膜炎球菌多糖疫苗（2）（与第1剂间隔>=3年）<br/>乙脑灭活疫苗（4）'
      ],
      boxColor:['#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01', '#e78b01'],
      dotColor:['#70a0ce', '#70a0ce', '#5493ce', '#5493ce', '#6296c9', '#6296c9', '#2d82b5', '#2d82b5',  '#2079a3', '#2079a3', '#2070a3', '#2070a3', '#015c92']
    }
    },
  methods: {
    changeActive(index){
      this.timeIndex = index;
      console.log('点击了时间点', index)
    },
    move(flag){
      if (flag){
        if (this.left > -2340) this.left = this.left - 260
      } else {
        if (this.left < 0) this.left = this.left + 260
      }
    }
  }
  }
</script>
<style scoped>
.timeline.body-bgcolor {
  overflow: hidden;
  width: 68vw;
  height: 50vh;
  padding: 0 70px;
  margin: 25vh auto;
  position: relative;
  font-family: 'qingning';
}
.timeline-container{
  position: absolute;
  /*left: 0;*/
  transition: all 1s ease-in-out;
}
.tipBox{
  /*position: absolute;*/
  /*top: 0;*/
  width: 230px;
  height: 150px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.tipBox>p{
  width: 85%;
  text-align: left;
  /*margin: ;*/
}
.dot{
  border:2px solid #DCDFE6;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: white;
  margin: 2px 0px;
  box-sizing: border-box;
}

.item{
  flex:1;
  width: 230px;
  border-bottom: 6px solid #00fff7;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.active{
  background-color: orange !important;
  /*border: 5px solid #67C23A;*/
  margin-top: -2px;
  box-sizing: content-box;
}
.left,.right{
  display: inline-block;
  width: 60px;
  height: 60px;
  background: url("../../assets/baby/arrow.png") center center no-repeat;
  background-size: contain;
}
.left{
  position: absolute;
  left: 0;
  top: 35%;
  transform: rotate(-90deg);
}
.right{
  position: absolute;
  right: 0;
  top: 35%;
  transform: rotate(90deg);
}
</style>
