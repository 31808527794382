<template>
<div class="control_panel">
  <div class="back" @click="back">
    <span></span>
  </div>
  <div class="head">
    <div class="head_text">
      <span>数据控制面板</span>
    </div>
    <div class="head_img"></div>
  </div>
  <div class="header"></div>
  <div class="content">
    <div class="left">
      <div>
        <dynamicBar></dynamicBar>
      </div>
      <div>
        <points2></points2>
      </div>
    </div>
    <div class="center">
      <div style="height: 400px">
        <points></points>
      </div>
      <div class="small" style="height: 200px;">
        <bar></bar>
      </div>
    </div>
    <div class="right">
      <div>
        <box></box>
      </div>
      <div>
        <pie></pie>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import points from "./echart/points";
import dynamicBar from "./echart/dynamicBar";
import box from "./echart/box";
import points2 from "./echart/points2";
import pie from "./echart/pie";
import bar from "./echart/bar";
// import sunbrust from "./echart/sunbrust";
export default {
  name: "control_panel",
  components:{
    points,
    dynamicBar,
    box,
    points2,
    pie,
    bar
    // sunbrust
  },
  data() {
    return{
      month:require('../assets/pregnant/1.png')
    }
  },
  methods:{
    back(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.control_panel{
  width: 100%;
  height: 100vh;
  background-color: #164463;
  overflow: hidden;
  font-family: qingning;
}
.head{
  width: 50%;
  height: 10vh;
  position: relative;
  margin: 0 auto;
  margin-top: 2vh;
}
.head_text{
  text-align: center;
  color: #fff;
  font-size: 22px;
  text-shadow: 0 0 10px #cc9b5f;
  line-height: 30px;
}
.head_img{
  background: url("../assets/panel/head.gif") no-repeat center center;
  background-size: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0.4rem;
}
.header{
  width: 100%;
  height: 2vh;
  margin-top: -2vh;
  background: url("../assets/panel/header.png") center center no-repeat;
  background-size: contain;
}
.control_panel>.content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.left,.center,.right{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /*height: ;*/
}
.control_panel>.content>div>div{
  width: 100%;
  height: 300px;
  margin: 20px;
  position: relative;
  transition: all 1s;
  border: 1px solid #fefd70;
}
/*.large{*/
/*  height: 400px;*/
/*}*/
/*.small{*/
/*  height: 200px;*/
/*}*/
.control_panel>.content>div>div:before{
  content: '';
  position: absolute;
  width: 80%;
  height: 100%;
  bottom: -1px;
  top: -1px;
  left: 10%;
  border-bottom: 1px solid #908b01;
  border-top: 1px solid #908b01;
  transition: all .5s;
}
.control_panel>.content>div>div:after{
  content: '';
  position: absolute;
  width: 100%;
  height: 80%;
  left: -1px;
  right: -1px;
  top: 10%;
  border-left: 1px solid #908b01;
  border-right: 1px solid #908b01;
  transition: all .5s;
}
.control_panel>.content>div>div:hover::before {
  width: 0%
}
.control_panel>.content>div>div:hover::after {
  height: 0%
}
.control_panel>.content>div>div:hover{
  box-shadow: -5px 0 2px rgba(255, 255, 255, .1), 0 -5px 2px rgba(255, 255, 255, .1), 5px 0 2px rgba(255, 255, 255, .1), 0 5px 2px rgba(255, 255, 255, .1);
  background: rgba(255, 255, 255, .1)
}
.back{
  display: inline-block;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 25px;
  left: 40px;
  z-index: 9999;
  background: url("../assets/pregnant/back.png") center center no-repeat;
  background-size: contain;
  /*animation: back 2s linear infinite;*/
}
.back:hover{
  animation: opacity 0.5s ease-in-out;
}
@keyframes opacity {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
.back>span{
  display: inline-block;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 25px;
  left: 40px;
  background-color: #164463;
  animation: back 8s ease-in infinite;
  animation-fill-mode: forwards;
  /*animation-direction: reverse;*/
  /*opacity: 0;*/
}
@keyframes back {
  0%{
    height: 50px;
  }
  2.5%{
    height: 37.5px;
  }
  5%{
    height: 25px;
  }
  7.5%{
    height: 12.5px;
  }
  10%,100%{
    height: 0;
  }
}
</style>
