<template>
<div class="card_container" :style="none" :class="{width:widthStyle}">
  <div class="title" :style="titleStyle">
    <div class="title_icon" v-show="data.titleIcon">
      <span :style="'background:url('+data.titleIcon+') center center no-repeat'" style="background-size: 80%"></span>
    </div>
    <span>{{data.title}}</span>
  </div>
  <div class="content">
    <div class="yingYangItem">
      <div class="itemTitle">
        <div class="img" v-show="data.item1Icon">
          <span :style="'background:url('+data.item1Icon+') center center no-repeat'" style="background-size: 80%"></span>
        </div>
        <span class="text" v-show="data.item1Title">{{ data.item1Title }}</span>
      </div>
      <div class="more" v-show="data.item1Content" :style="style" v-html="data.item1Content"></div>
    </div>
    <div class="shiPuItem">
      <div class="itemTitle">
        <div class="img" v-show="data.item2Icon">
          <span :style="'background:url('+data.item2Icon+') center center no-repeat'" style="background-size: 80%"></span>
        </div>
        <span class="text" v-show="data.item2Title">{{data.item2Title}}</span>
      </div>
      <div class="more" v-show="data.item2Content" v-html="data.item2Content"></div>
    </div>
    <div class="haoChuItem" v-show="data.item3Title">
      <div class="itemTitle">
        <div class="img" v-show="data.item3Icon">
          <span :style="'background:url('+data.item3Icon+') center center no-repeat'" style="background-size: 80%"></span>
        </div>
        <span class="text" v-show="data.item3Title">{{data.item3Title}}</span>
      </div>
      <div class="more" v-show="data.item3Content" v-html="data.item3Content"></div>
    </div>
<!--    <slot name="content"></slot>-->
  </div>
<!--  <div class="img">-->
<!--    <slot name="img"></slot>-->
<!--  </div>-->
</div>
</template>

<script>
export default {
  name: "card",
  props:{
    data:{
      type:Object,
      default:()=>{}
    }
  },
  mounted(){
    let that = this;
    if (!this.data.item1Title) {
      this.style = "font-size:25px;margin:0 auto;line-height:40px";
      this.titleStyle = "font-size:36px;"
      // this.none = this.none + ""
      this.widthStyle = true;
      if (this.$parent.funnel) {
        this.style = 'font-size:25px;margin:0 auto;line-height:40px;text-indent:0em;'
        this.widthStyle = false;
      } else {
        this.widthStyle = true;
      }
    } else{
      this.style = "";
      this.titleStyle = "";
      this.widthStyle = false;
    }
    this.none = "display:none";
    setTimeout(function (){
      that.none = "";
    }, 200)
  },
  watch:{
    data(){
      let that = this;
      this.none = "display:none";
      setTimeout(function (){
        that.none = "";
      }, 200)
      if (!this.data.item1Title) {
        this.style = "font-size:25px;margin:0 auto;line-height:40px";
        this.titleStyle = "font-size:36px;"
        this.widthStyle = true;
        if (this.$parent.funnel) {
          this.style = 'font-size:25px;margin:0 auto;line-height:40px;text-indent:0em;'
          this.widthStyle = false;
        } else {
          this.widthStyle = true;
        }
      } else{
        this.style = "";
        this.titleStyle = "";
        this.widthStyle = false
      }

    }
  },
  data(){
    return{
      // titleIcon:require('../../assets/img/luobo.png')
      none:"",
      style:"",
      titleStyle:"",
      widthStyle:false
    }
  }
}
</script>

<style scoped>
.card_container{
  width: 40%;
  height: 75%;
  margin: 10%;
  margin-left: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*margin-left: 0;*/
  font-family: 'qingning';
}
.width{
  width: 36%;
  margin-top:100px
}
.card_container>.title {
  font-size: 32px;
  line-height: 50px;
  /*margin: 30px auto;*/
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  opacity: 0;
  animation: show 1s ease-in;
  animation-fill-mode: forwards;
}
.title_icon>span{
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-size: contain;
  z-index: 9999;
}
.title_icon{
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: #d1e6eb;
  margin-right: 30px;
}
.card_container>.content {
  /*font-size: 24px;*/
  /*text-indent: 2em;*/
  /*line-height: 50px;*/
  /*text-align: left;*/
  opacity: 0;
  animation: show 1s ease-in 0.5s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card_container>.content>div{
  /*width: 70vh;*/
  height: 130px;
  margin-bottom: 15px;
  /*background-color: white;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.itemTitle{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
}
.img{
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 32px;
  background-color: #fff;
}
.img>span{
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 32px;
  background-size: 80%;
  z-index: 999;
}
.text{
  font-size: 24px;
}
.more{
  text-indent: 2em;
  font-size: 19px;
  margin-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}
.yingYangItem .img>span{
  /*background: url("../../assets/img/yingyang.png") center center no-repeat;*/
  background-size: 80%;
}
.shiPuItem .img>span{
  /*background: url("../../assets/img/caipu.png") center center no-repeat;*/
  background-size: 80%;
}
.haoChuItem .img>span{
  /*background: url("../../assets/img/haochu.png") center center no-repeat;*/
  background-size: 80%;
}
@keyframes show {
  0%{
    opacity: 0;
    margin-top: 10px;
  }
  25%{
    opacity: 0.25;
    margin-top: 7.5px;
  }
  50%{
    opacity: 0.5;
    margin-top: 5px;
  }
  75%{
    opacity: 0.75;
    margin-top: 2.5px;
  }
  100%{
    opacity: 1;
    margin-top: 0;
  }
}
</style>
