<template>
<div class="main">
  <myNav ref="nav"></myNav>
<!--  <pregnant v-if="pregnant"></pregnant>-->
  <FenMain2 v-if="pregnant"></FenMain2>
  <after v-if="after"></after>
  <baby v-show="baby"></baby>
<!--  <span class="resource">中华人民共和国国家卫生健康委员会</span>-->
</div>
</template>

<script>
import myNav from "./public_components/myNav";
// import pregnant from "./pregnant/pregnant";
import FenMain2 from "./FenMain2";
import after from "./after/after";
import baby from "./baby/baby";
export default {
  name: "main",
  components:{
    myNav,
    // pregnant,
    FenMain2,
    after,
    baby,
  },
  mounted() {
    let flag = this.$route.query.flag;
    if (flag == 1){
      this.pregnant = true;
      this.after = false;
      this.baby = false
    } else if (flag == 2){
      this.pregnant = false;
      this.after = true;
      this.baby = false;
    } else {
      this.pregnant = false;
      this.after = false;
      this.baby = true;
    }
    this.$refs.nav.show(flag);
  },
  data() {
    return {
      pregnant:true,
      after:false,
      baby:false
    }
  }
}
</script>

<style scoped>
.main{
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
/*.resource{*/
/*  position: fixed;*/
/*  bottom: 0;*/
/*  right: 10px;*/
/*}*/
</style>
