<template>
  <div id="bar" :style="{width: '100%', height: '110%'}"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "bar",
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {

      let getchart = echarts.init(document.getElementById('bar'));
      var fw=[
        {
          xx:15.3,sx:21.4
        },
        {
          xx:22,sx:25.1
        },
        {
          xx:22.4,sx:29
        },
        {
          xx:25.3,sx:32
        },
        {
          xx:29.8,sx:34.5
        },
        {
          xx:33,sx:37
        }
      ];
      var option = {
        title: {
          text: '孕妈宫高标准',
          top:10,
          left:170,
          textStyle: {
            fontSize: 18,//字体大小
            color: '#ffffff',//字体颜色
            fontFamily:'qingning',
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['孕5月', '孕6月', '孕7月', '孕8月', '孕9月', '孕10月'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        yAxis: {
          // type: 'value',
          type: 'value',
          min: 0,
          max: 40,
          boundaryGap: [0, '100%'],
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },

        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var result =params.marker  + params.name +  '标准：'+ params.value+'cm ；范围：'+fw[params.dataIndex].xx+'~'+fw[params.dataIndex].sx+'cm'

            return result
          }
        },
        series: [
          {
            data: [18, 24, 26, 29, 32, 35],
            type: 'line',
            // areaStyle: {}
            lineStyle:{
              color:'#e5ad6a'
            },
            // areaStyle: {
            //
            // },
            itemStyle:{
              color: '#e5ad6a'
            }
          }
        ]
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
