<template>
<div class="baby_container">
<!--  <myNav></myNav>-->
  <div class="content">
    <funnel v-if="funnel" @funnel_clicked="changeCard"></funnel>
    <div style="width: 80vw;height: 70vh"  v-if="discUsage">
      <discUsage v-if="discUsage"></discUsage>
    </div>

<!--    <tree v-if="tree"></tree>-->
    <timeLine v-show="yimiao"></timeLine>
    <card :data="data" v-show="!yimiao && !discUsage"></card>
<!--    <funnel></funnel>-->
    <span class="resource">{{resource}}</span>
  </div>

  <div class="aside" :style="marginRight">
    <span>欢迎光临宝宝馆</span>
    <div class="plus" :style="right" @click="showAside">
      <span :style="rotate"></span>
    </div>
    <li  @click="showChart(index)" v-for="(item,index) in List" :key="index" >
      <span :style="'background:url('+item.img+') center center no-repeat'" style="background-size: contain"></span>
      <a href="#">{{item.name}}</a>
    </li>
  </div>
</div>
</template>

<script>
// import show_chart from "../public_components/show_chart";
// import show_chart2 from "../public_components/show_chart2";
// import myNav from "../public_components/myNav";
// import tree from "../echart/tree";
import card from "../public_components/card";
import funnel from "../echart/funnel";
import timeLine from "../public_components/timeLine";
import discUsage from "../echart/discUsage";
export default {
  name: "baby",
  components:{
    // myNav,
    // show_chart,
    // show_chart2
    // tree,
    card,
    funnel,
    timeLine,
    discUsage
  },
  data() {
    return{
      marginRight:"margin-right: -15vw;",
      right:"right: 0;",
      rotate:"",
      yimiao:false,
      funnel:true,
      // tree:false,
      discUsage:false,
      resource:"数据来源于中国营养学会妇幼营养分会",
      flag: 0,
      data:{
        title:"蔬菜水果",
        // item1Title:'营养要素',
        item1Content:'土豆，富含蛋白质和微量元素<br/>' +
            '南瓜，含各种维生素、糖类以及蛋白质<br/>' +
            '扁豆，含维生素B族和维生素C<br/>' +
            '菜花，富含维生素K及类黄酮<br/>' +
            '火龙果，通便，防止婴儿便秘<br/>' +
            '柑橘，富含维生素C，有助于增进食欲<br/>' +
            '苹果，富含维生素C和钾离子，可降低血压，增强免疫<br/>' +
            '猕猴桃，富含维生素和微量元素，可改善脾胃功能<br/>',
      },
      List:[
        {
          name:"膳食宝塔",
          // margin:"margin-right: 0"
          img:require('../../assets/after/location1.png')
        },
        {
          name:"病症诊疗室",
          // margin:""
          img:require('../../assets/after/location2.png')
        },
        {
          name:"疫苗展览厅",
          // margin:""
          img:require('../../assets/after/location3.png')
        }
      ],
      babyData2:[{
        title:"护理信息",
        content:"眼睛——6个月的宝宝，眼睛已经开始逐渐可以对焦，并且调整自己的姿势，这样能看清楚自己想看的东西。这个时候妈妈们要好好护理眼睛，最好的建议是定期带宝宝去做专业的视力检查和护理，防止宝宝出现斗鸡眼的现象。<br/>" +
            "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp口腔——6个月宝宝的牙齿开始慢慢出现，这个时候的口水分泌是很多的，很多宝宝会出现流口水的现象，这个是正常的。所以这个时候口腔护理很重要了，经常擦拭口水，避免口水长期滞留在衣物上和嘴角处，引发细菌感染。<br/>" +
            "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp睡眠——睡眠对于宝宝来说一直很重要，这个时候的宝宝活动时间长范围更大，所以体力消耗也更大，这个时候就应该保证宝宝有充足的睡眠，养成良好的睡眠习惯。\n",
        img:require('../../assets/img/婴儿护理信息背景.png')
      }],
      data3:[
        {
          title:"蔬菜水果",
          // item1Title:'营养要素',
          item1Content:'土豆，富含蛋白质和微量元素<br/>' +
              '南瓜，含各种维生素、糖类以及蛋白质<br/>' +
              '扁豆，含维生素B族和维生素C<br/>' +
              '火龙果，通便，防止婴儿便秘<br/>' +
              '苹果，富含维生素C和钾离子，可降低血压，增强免疫<br/>' +
              '猕猴桃，富含维生素和微量元素，可改善脾胃功能<br/>',
        },
        {
          title:"谷类",
          // item1Title:'营养要素',
          item1Content:
              '玉米粉，富含钙、氨基酸、维生素B2等，有利于智力发育、铁的吸收<br/>' +
              '小米粥，富含钙、矿物质等，能较好保护胃黏膜，促进生长发育<br/>' +
              '麦片粥，含大量膳食纤维及维生素B1，促进排便，提高注意力、改善记忆力<br/>' +
              '大米粥，富含蛋白质，易于吸收消化，不易导致过敏'
        }
      ]
    }
  },
  methods:{
    showAside(){
      if (!this.rotate){
        this.marginRight = "margin-right: 0;"
        this.right = "right: 15vw;"
        this.rotate = "transform: rotate(45deg);"
      } else{
        this.marginRight = "margin-right: -15vw"
        this.right = "right: 0;"
        this.rotate = ""
      }
    },
    closeChart(flag) {
      this.close = flag;
      this.close1 = flag;
    },
    chartShow(id, flag){
      this.close = false;
      this.flag = id;
      if (id == 6) {
        this.data = this.data2
      } else if (id == 2){
        this.data = this.data0
      } else if (id == 3){
        this.data = this.data1
      }
      // } else if ((id == 6)&&(flag == 1)){
      //   this.data = this.data2
      // } else if ((id == 6)&&(flag == 2)){
      //   this.data = this.data3
      // }
    },
    showChart(index) {
      // let that = this;
      // this.close = false;
      this.showAside();
      console.log(index);
      if (index == 0) {
        // this.sunBrust = true;
        // this.radar = false;
        this.yimiao = false;
        this.funnel = true;
        this.discUsage = false;
        this.resource = "数据来源于中国营养学会妇幼营养分会";
      } else if (index == 1){
        this.yimiao = false;
        this.funnel = false;
        this.discUsage = true;
        this.resource = "数据来源于妈妈网";
      } else {
        this.yimiao = true;
        this.funnel = false;
        this.discUsage = false;
        this.resource = "数据来源于中华人民共和国国家卫生健康委员会";
      }
      // this.clockShow = !this.clockShow;
      this.List[index].margin = "margin-right: 0;";
      for (let i = 0; i < this.List.length; i++) {
        if (i != index) this.List[i].margin = "";
      }
    },
    changeCard(name){
      let that = this;
      switch (name){
        case '蔬菜类和水果类各25-100克':
          that.data = that.data3[0];
          break;
        case '肉禽鱼和谷类各25-75克':
          that.data = that.data3[1];
          break;
      }
    }
  }
}
</script>

<style scoped>
.baby_container{
  width: 100%;
  height: 100vh;
  background: #27869c;
  font-family: 'qingning';
  /*background-size: cover;*/
  overflow: hidden;
  /*background-size: 65%;*/
}
.baby_container>.content{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.baby_container>.content>.nav{
  width: 180px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /*border-top: darkgrey 3px solid;*/
  /*border-left: darkgrey 3px solid;*/
  /*border-bottom: darkgrey 3px solid;*/
  /*border-top-left-radius: 30px;*/
  /*border-bottom-left-radius: 30px;*/
  /*background-color: rgba(194, 14, 14, 0.5);*/
  position: absolute;
  right: 0;
  top: 100px;
  overflow: hidden;
  z-index: 99;
}
.baby_container .nav div{
  /*width: 48px;*/
  height: 48px;
  display: inline-block;
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  /*border-radius: 24px;*/
}
.baby_container .nav a{
  width: 48px;
  height: 48px;
  display: inline-block;
}
.baby_container .nav>div:first-child>a{
  background: url("../../assets/baby/yinshi.png") center center no-repeat;
  background-size: contain;
  animation: leftAndRight1 1.5s ease-in infinite;
}
.baby_container .nav>div:nth-child(2)>a{
  background: url("../../assets/baby/fayu.png") center center no-repeat;
  background-size: contain;
  animation: leftAndRight1 1.5s ease-in infinite 0.1s;
}
.baby_container .nav>div:nth-child(3)>a{
  background: url("../../assets/baby/yimiao.png") center center no-repeat;
  background-size: contain;
  animation: leftAndRight1 1.5s ease-in infinite 0.2s;
}
.baby_container .nav>div:nth-child(4)>a{
  background: url("../../assets/baby/yufang.png") center center no-repeat;
  background-size: contain;
  animation: leftAndRight1 1.5s ease-in infinite 0.3S;
}
@keyframes leftAndRight1 {
  0%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
  25%{
    transform: translateY(5px);
    /*transform: translateY(-5px);*/
  }
  50%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
  75%{
    transform: translateY(-5px);
    /*transform: translateY(5px);*/
  }
  100%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
}
@keyframes leftAndRight2 {
  0%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
  25%{
    transform: translateY(-5px);
    /*transform: translateY(5px);*/
  }
  50%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
  75%{
    transform: translateY(5px);
    /*transform: translateY(-5px);*/
  }
  100%{
    transform: translateY(0);
    /*transform: translateY(0);*/
  }
}

.opacity{
  opacity: 1;
}
.aside{
  width: 15vw;
  height: 100vh;
  background-color: rgba(252, 253, 253, 0.5);
  /*border-radius: 10px;*/
  /*border-bottom-left-radius: 10px;*/
  /*border-top-left-radius: 10px;*/
  /*border-top: 3px solid #47778b;*/
  /*border-bottom: 3px solid #47778b;*/
  /*border-left: 3px solid #47778b;*/
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /*margin-right: -100%;*/
  /*overflow: hidden;*/
  z-index: 99;
  transition: all 1s ease-in-out;
}
.aside>span{
  display: inline-block;
  position: absolute;
  top: 100px;
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: bolder;
  /* margin: 0 auto; */
  color: white;
}
.plus{
  /*content: "";*/
  display: inline-block;
  width: 50px;
  height: 30px;
  position: fixed;
  /*right: 0;*/
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: rgba(252, 253, 253, 0.5);
  transition: all 1s ease-in-out;
  background-position: center center;
  /*transform: rotate(45deg);*/
  /*background-repeat: no-repeat;*/
  /*background-size: 35%;*/
}
.plus>span{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px;
  background: url("../../assets/after/plus.png") center center no-repeat;
  background-size: contain;
  transition: all 1s ease-in-out;
  /*transform: rotate(45deg);*/
}
li{
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.aside>li{
  width: 90%;
  height: 25px;
  font-size: 20px;
  line-height: 25px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-right: -80%;*/
  /*background-color: rgba(215,215,215,0.7);*/
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s cubic-bezier(.74,.2,.37,1.07);
}
.aside>li:hover{
  /*margin-right: 0;*/
}
.aside>li>span{
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  /*background: #27869c;*/
}
.aside>li>a{
  text-decoration: none;
  width: 55%;
  color: black;
  text-align: left;
  font-weight: bolder;
}
.resource{
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #fbffff;
}
</style>
