<template>
  <div id="dynamicBar" :style="{width: '100%', height: '100%',margin: '10px auto'}"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "dynamicBar",
  data() {
    return {
      data: []
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {

      let getchart = echarts.init(document.getElementById('dynamicBar'));
      const categories2 = [0,1,2,3,4,5,6]
      const data=[3.32,4.51,5.68,6.70,7.45,8.00,8.41];
      const data2 = [3.21,4.20,5.21,6.13,6.83,7.36,7.77];
      var fw=[
        {
          xx:2.26,sx:4.66
        },
        {
          xx:3.09,sx:6.33
        },
        {
          xx:3.94,sx:7.97
        },
        {
          xx:4.69,sx:9.37
        },
        {
          xx:5.25,sx:10.39
        },
        {
          xx:5.66,sx:11.15
        },
        {
          xx:5.97,sx:11.72
        }
      ];
      var fw2=[
        {
          xx:2.26,sx:4.65
        },
        {
          xx:2.98,sx:6.05
        },
        {
          xx:3.72,sx:7.46
        },
        {
          xx:4.40,sx:8.71
        },
        {
          xx:4.93,sx:9.66
        },
        {
          xx:5.33,sx:10.38
        },
        {
          xx:5.64,sx:10.93
        }
      ]
      var option = {
        title: {
          text: '婴儿体重标准表',
          textStyle:{
            color: '#ffffff',
            fontFamily:'qingning'
          }
        },
        legend: [
          {
            top: "0%",
            right: "22%",
            textStyle: {
              // 图例文字样式
              color: "white",
              fontSize: 12,
              fontFamily:'qingning'
            },
            itemWidth: 25, // 图例图形的宽度
            itemHeight: 12, // 图例图形的高度
            data: [
              {
                name: "男宝宝", // 图例文字内容
                // icon: "roundRect" // 图例图形的形状，有多个可选值：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'，
                //也可引用自定义的图片
                //  icon: "image://https://p1.pstatp.com/list/dfic-imagehandler/8ac89fbe-b30c-4ba0-8d75-02c0f60a7ea6"
              }
            ]
          },
          {
            top: "0%",
            right: "5%",
            textStyle: {
              // 图例文字样式
              color: "white",
              fontSize: 12,
              fontFamily:'qingning'
            },
            itemWidth: 25, // 图例图形的宽度
            itemHeight: 12, // 图例图形的高度
            data: [
              {
                name: "女宝宝", // 图例文字内容
                // icon: "roundRect" // 图例图形的形状，有多个可选值：'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'，
                //也可引用自定义的图片
                //  icon: "image://https://p1.pstatp.com/list/dfic-imagehandler/8ac89fbe-b30c-4ba0-8d75-02c0f60a7ea6"
              }
            ]
          },
        ],
        // toolbox: {
        //   show: true,
        //   feature: {
        //     dataView: { readOnly: false },
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: categories2,
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          },
          {
            type: 'category',
            boundaryGap: true,
            data: categories2,
            axisLabel: {
              show: true,
              textStyle: {
                color: 'rgba(39,134,156,0)',
                // opacity:0
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            // name: 'Price',
            max: 12,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff'
              }
            }
          },
          {
            type: 'value',
            scale: true,
            // name: 'Order',
            max: 12,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLabel: {
              show: true,
              textStyle: {
                color: 'rgba(39,134,156,0)',
                opacity:0
              }
            }
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            // params = params[0];
            if (params.seriesName == '男宝宝') var result =params.marker  + ' '+params.name +  '月龄<br/>标准：'+ params.value+'kg ；<br/>范围：'+fw[params.dataIndex].xx+'~'+fw[params.dataIndex].sx+'kg'
            else result = params.marker  + ' '+params.name +  '月龄<br/>标准：'+ params.value+'kg ；<br/>范围：'+fw2[params.dataIndex].xx+'~'+fw2[params.dataIndex].sx+'kg'
            console.log(params);
            return result

            // var date = new Date(params.name);
            // return (
            //     date.getDate() +
            //     '/' +
            //     (date.getMonth() + 1) +
            //     '/' +
            //     date.getFullYear() +
            //     ' : ' +
            //     params.value[1]
            // );
          },
        },
        series: [
          {
            name: '男宝宝',
            type: 'bar',
            // xAxisIndex: 1,
            yAxisIndex: 1,
            data: data,
            itemStyle: {
              barBorderRadius: [2, 2, 0, 0], //柱体圆角
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#f8c181' },
                { offset: 1, color: '#fefd70' }
              ])
              // color: new echarts.graphic.LinearGradient(
              //     //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
              //     //通过修改前4个参数，可以实现不同的渐变方向
              //     /*第五个参数则是一个数组，用于配置颜色的渐变过程。
              //       每项为一个对象，包含offset和color两个参数
              //     */
              //     0, 0, 0, 1, [{//代表渐变色从正上方开始
              //       offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
              //       color: '#70eec6'
              //     }, //柱图渐变色
              //       {
              //         offset: 1, //指100%处的颜色
              //         color: '#ecf87a'
              //       }
              //     ]
              // ),
            }
          },
          {
            name: '女宝宝',
            type: 'bar',
            data: data2,
            itemStyle:{
              color: '#e5983e'
            }
          }
        ]
      };
      var app = {};
      app.count = 7;
      var i = 7;
      var j = 7;
      app.data = [3.32,4.51,5.68,6.70,7.45,8.00,8.41,8.76,9.05,9.33,9.58,9.83,10.05];
      app.data2 = [3.21,4.20,5.21,6.13,6.83,7.36,7.77,8.11,8.41,8.69,8.94,9.18,9.40];
      app.fw = [
          {
        xx:2.26,sx:4.66
      },
        {
          xx:3.09,sx:6.33
        },
        {
          xx:3.94,sx:7.97
        },
        {
          xx:4.69,sx:9.37
        },
        {
          xx:5.25,sx:10.39
        },
        {
          xx:5.66,sx:11.15
        },
        {
          xx:5.97,sx:11.72
        },
        {
          xx:6.24,sx:12.20
        },
        {
          xx:6.46,sx:12.60
        },
        {
          xx:6.67,sx:12.99
        },
        {
          xx:6.86,sx:13.34
        },
        {
          xx:7.04,sx:13.68
        },
        {
          xx:7.21,sx:14.00
        }
        ];
      app.fw2 = [
        {
          xx:2.26,sx:4.65
        },
        {
          xx:2.98,sx:6.05
        },
        {
          xx:3.72,sx:7.46
        },
        {
          xx:4.40,sx:8.71
        },
        {
          xx:4.93,sx:9.66
        },
        {
          xx:5.33,sx:10.38
        },
        {
          xx:5.64,sx:10.93
        },
        {
          xx:5.90,sx:11.40
        },
        {
          xx:6.13,sx:11.80
        },
        {
          xx:6.34,sx:12.18
        },
        {
          xx:6.53,sx:12.52
        },
        {
          xx:6.71,sx:12.85
        },
        {
          xx:6.87,sx:13.15
        }
      ]
      setInterval(function () {
        // let axisData = new Date().toLocaleTimeString().replace(/^\D*/, '');
        // categories.shift();
        // categories.push(axisData);
        if (app.count>12&&i>12) {
          // categories2 = [0,1,2,3,4,5,6]
          // categories2 = [0,1,2,3,4,5,6]
          // data=[3.32,4.51,5.68,6.70,7.45,8.00,8.41];
          // data2 = [3.21,4.20,5.21,6.13,6.83,7.36,7.77];
          app.count = 0
          i = 0
          j = 0
        }
        data.shift();
        data.push(app.data[i]);
        fw2.shift();
        fw2.push(app.fw[i])
        fw.shift();
        fw.push(app.fw[i++])
        data2.shift();
        data2.push(app.data2[j++]);
        categories2.shift();
        categories2.push(app.count++);
        getchart.setOption({
          xAxis: [
            {
              data: categories2
            },
            {
              data: categories2
            }
          ],
          series: [
            {
              data: data
            },
            {
              data: data2
            }
          ]
        });
      }, 2100);
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
