<template>
<div id="funnel" :style="{float:'left',width: '50vw', height: '70vh',margin: '18vh 0 12vh 0'}"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "funnel",
  props:{
    List:{
      type: Array,
      default:()=>[
        {
          value: 60,
          name: '肉禽鱼和谷类各25-75克',
          itemStyle:{
            backgroundColor:"#87c9f5"
          }
        },
        { value: 40, name: '鸡蛋15-50克',itemStyle:{
            backgroundColor:"#f48785"
          }},
        { value: 20, name: '盐油0-10克',itemStyle:{
            backgroundColor:"#878df4"
          }},
        { value: 80, name: '蔬菜类和水果类各25-100克',itemStyle:{
            backgroundColor:"#f4b586"
          }},
        { value: 100, name: '母乳700-500毫升',
          itemStyle:{
            backgroundColor:"#f5ec86"
          }}]
    }
  },
  data() {
    return {
      data:[
        { value: 60, name: 'Visit' },
        { value: 40, name: 'Inquiry' },
        { value: 20, name: 'Order' },
        { value: 80, name: 'Click' },
        { value: 100, name: 'Show' }
      ]
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart(this.List)
  },
  methods: {

    initChart(data) {
      let that = this;
      let getchart = echarts.init(document.getElementById('funnel'));
      var option = {
        title: {
          // text: '宝宝饮食',
          textStyle:{
            color:"#fff"
          }
        },
        color:["#f5ec86","#87c9f5", "#f48785","#878df4","#f4b586"],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}%'
        },
        toolbox: {
          feature: {
            // dataView: { readOnly: false },
            // restore: {},
            // saveAsImage: {}
          }
        },
        legend: {
          data: data,
          textStyle:{
            color:"#fff",
            fontFamily:"qingning"
          },
          // bottom:0
        },
        series: [
          {
            name: '宝宝饮食',
            type: 'funnel',
            sort: 'ascending',
            left: '10%',
            top: 60,
            bottom: 60,
            width: '80%',
            min: 0,
            max: 100,
            minSize: '0%',
            maxSize: '100%',
            // sort: 'descending',
            gap: 2,
            label: {
              show: true,
              position: 'inside',
              fontFamily:"qingning",
              color:"#3e3d3d",
              fontSize: 16,
              fontWeight:'bolder'
              // textShadowOffsetX:2,
              // textShadowOffsetY:2,
              // textShadowColor:'#000'
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                color:"#000",
                type: 'solid'
              }
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
              shadowBlur: 2,             //整个外面的阴影厚度
              shadowOffsetX: 0,
              shadowOffsetY: 2,          //每一块的x轴的阴影
              shadowColor: 'rgba(0, 0, 0, 0.5)'    //阴影颜色
              // textStyle:{
              //   // outline:"#000 dotted thick"
              //   textShadowOffsetX:2,
              //   textShadowOffsetY:2,
              //   textShadowColor:'#000',
              //   // textShadow:"2px 2px darkgray"
              // }
            },
            emphasis: {
              label: {
                fontSize: 20
              }
            },
            data: data
          }
        ]
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
      getchart.on('click',function (param){
        console.log(param.data);
        that.$emit('funnel_clicked',param.data.name);
      })
    }
  }
}
</script>

<style scoped>

</style>
