<template>
<div class="nav_container">
  <ul class="top_nav">
    <li @click="show(0)" >
<!--      <a href="#"></a>-->
      首页
    </li>
    <li @click="show(4)" >
<!--      <a href="#"></a>-->
      指标馆
    </li>
    <li @click="show(1)" >
<!--      <a href="#"></a>-->
      孕妈馆
    </li>
    <li @click="show(2)" >
<!--      <a href="#"></a>-->
      产妇馆
    </li>
    <li @click="show(3)" >
<!--      <a href="#"></a>-->
      宝宝馆
    </li>
  </ul>
  <div class="move" :style="left"></div>
</div>
</template>

<script>
export default {
  name: "myNav",
  data() {
    return {
      // pregnant: true,
      // after: false,
      // baby: false,
      left:"left:40%"
    }
  },
  methods:{
    show(id) {
      // localStorage.setItem('nav_id', id);
      // // console.log(this.$route.path);
      let that = this;
      // let nav = localStorage.getItem('nav_id')
      // console.log(nav);
      if (id == 1 ){
        setTimeout(function (){
          that.$parent.pregnant = true;
          that.$parent.after = false;
          that.$parent.baby = false;
        },300)
        this.left = "left:40%";
        // this.$router.push('/pregnant');
      } else if (id == 2){
        // console.log(nav);
        setTimeout(function (){
          that.$parent.pregnant = false;
          that.$parent.after = true;
          that.$parent.baby = false;
        },300)
        this.left = "left:60%";
        // this.$router.push('/after');
        // console.log(nav);
      } else if (id == 3){
        setTimeout(function (){
          that.$parent.pregnant = false;
          that.$parent.after = false;
          that.$parent.baby = true;
        },300)
        this.left = "left:80%"
        // this.$router.push('/baby')
      } else if(id == 4){
        this.$router.push('/control_panel')
      } else {
        this.$router.push('/')
      }
      // this.$emit('chose', id)
    }
  }
}
</script>

<style scoped>
.nav_container{
  width: 40%;
  height: 50px;
  line-height: 40px;
  z-index: 9;
  position: absolute;
  left: 0;
  font-family: 'qingning';
  /*border-top: darkgrey 3px solid;*/
  /*border-left: #bec9cd 3px solid;*/
  /*border-bottom: #bec9cd 3px solid;*/
  /*border-top-left-radius: 5px;*/
  /*border-bottom-left-radius: 5px;*/
  background-color: #17719c;
}
.top_nav{
  width: 100%;
  height: 100%;
  /*line-height: 100%;*/
  display: flex;
  justify-content: space-around;
}
ul,li{
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
}
.top_nav>li{
  width: 25%;
  transition: all 0.3s ease-in;
  font-size: 20px;
  height: 90%;
  line-height: 50px;
  margin-top: 5px;
  color: white;
}
.top_nav>li>a{
  display: inline-block;
  width: 100%;

  text-decoration: none;

}
/*.top_nav>li:hover{*/
/*  background-color: rgba(255, 255, 255, 0.8);*/
/*}*/
.chose{
  background-color: rgba(255, 255, 255, 0.8);
}
.move{
  width: 20%;
  height: 100%;
  position: absolute;
  /*left: 24%;*/
  background: url("../../assets/nav.png") center bottom no-repeat;
  background-size: 100% 90%;
  top: 0;
  z-index: -1;
  transition: all 0.3s cubic-bezier(.85,.27,.25,.91);
}
</style>
