<template>
<div id="tree" :style="{float:'left',width: '55vw', height: '80vh'}"></div>
</template>

<script>
import * as echarts from 'echarts'
// import axios from 'axios'
export default {
  name: "tree",
  props:{
    List:{
      type: Array,
      default:()=>[]
    }
  },
  data() {
    return {
      treedata:[{ //一定一定要注意这里有[]
        "name": "保养恢复",
        itemStyle:{
          color:'#8c4646'
        },
        "children": [
          {
            "name": "恶露",
            lineStyle:{
              color:'#ff7a00',
              width:5
            },
            itemStyle:{
              color:'#bc5952'
            },
            "children": [
            ]
          },
          {
            "name": "身体清洁",
            lineStyle:{
              color:'#ff7a00',
              width:5
            },
            itemStyle:{
              color:'#bc5952'
            },
            "children": [
            ]
          },
          {
            "name": "阴伤口清洁",
            lineStyle:{
              color:'#ff7a00',
              width:5
            },
            itemStyle:{
              color:'#bc5952'
            },
            "children": [
              {
                "name": "保持清洁",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "保持干燥",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 1759,
                itemStyle:{
                  color:'#db6b5c'
                },},
              {
                "name": "坚持做盆底运动",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 2165,
              itemStyle:{
                  color:'#db6b5c'
                },},
              {
                "name": "保持大便通畅",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 586,
              itemStyle:{
                  color:'#db6b5c'
                },},
              {
                "name": "注意伤口肿胀、疼痛",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 3331,
              itemStyle:{
                  color:'#db6b5c'
                },},
              {
                "name": "注意伤口感染",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 772,
              itemStyle:{
                  color:'#db6b5c'
                },},
            ]
          },
          {
            "name": "及时就医的情况",
            lineStyle:{
              color:'#ff7a00',
              width:5
            },
            itemStyle:{
              color:'#bc5952'
            },
            "children": [
              {
                "name": "体温高于37.5°C",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 8833,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "恶露异味",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 1732,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "恶露淋漓不尽",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 3623,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "伤口疼痛、有硬结",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 10066,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "乳房胀痛、有肿块",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 10066,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "腹部疼痛、宫底压痛",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 10066,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
              {
                "name": "情绪抑郁、睡眠障碍",
                lineStyle:{
                  color:'#ff7a00',
                  width:5
                },
                "value": 10066,
                itemStyle:{
                  color:'#db6b5c'
                },
              },
            ]
          },
        ]
      }]
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart(this.treedata)
  },
  methods: {

    initChart(data) {
      let that = this;
      let getchart = echarts.init(document.getElementById('tree'));
      // const lineStyle = {
      //   width: 1,
      //   opacity: 0.5
      // };
      // getchart.showLoading();
      // getchart.hideLoading();
      var option = {
        title: {
          text: ''
        },
        symbol:'circle',
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            // orient: "TB",
            data: data,

            top: '1%',
            left: '14%',
            bottom: '1%',
            right: '20%',

            symbolSize: 21,

            lineStyle:{
              width:2
            },
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              color:'#fff',
              fontFamily:'qingning',
              fontSize: 20,
              // fontWeight:'bolder'
            },

            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 16
              }
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
      getchart.on('click',function (param){
        console.log(param.data);
        that.$emit('tree_clicked',param.data.name);
      })
    }
  }
}
</script>

<style scoped>

</style>
