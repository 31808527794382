<template>
  <div id="box" :style="{width: '100%', height: '95%',margin: '10px auto'}"></div>
</template>

<script>
import * as echarts from  "echarts";
export default {
  name: "box",
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart() {
      let that = this
      let getchart = echarts.init(document.getElementById('box'));
      // function makeData() {
      //   let data = [];
      //   for (let i = 0; i < 18; i++) {
      //     let cate = [];
      //     for (let j = 0; j < 100; j++) {
      //       cate.push(Math.random() * 200);
      //     }
      //     data.push(cate);
      //   }
      //   return data;
      // }
      // const data0 = makeData();
      // const data1 = makeData();
      // const data2 = makeData();
      var dataset =  [
        {
          source: [
            [30.9,34.5,37.9],
            [33.3,36.9,40.7],
            [35.2,38.9,42.9],
            [36.7,40.5,44.6],
            [38.0,41.7,45.9],
            [39.0,42.7,46.9],
            [39.8,43.6,47.7],
            [40.4,44.2,48.4],
            [41.0,44.8,48.9],
            [41.5,45.3,49.4],
            [41.9,45.7,49.8],
            [42.3,46.1,50.2],
            [42.6,46.4,50.5]
          ]
        },
        {
          source: [
            [30.4,34.0,37.5],
            [32.6,36.2,39.9],
            [34.5,38.0,41.8],
            [36.0,39.5,43.4],
            [37.2,40.7,44.6],
            [38.1,41.6,45.7],
            [38.9,42.4,46.5],
            [39.5,43.1,47.2],
            [40.1,43.6,47.7],
            [40.5,44.1,48.2],
            [40.9,44.5,48.6],
            [41.3,44.9,49.0],
            [41.5,45.1,49.3]
          ]
        },
        {
          // fromDatasetIndex: 0,
          transform: { type: 'boxplot' }
        },
        {
          // fromDatasetIndex: 1,
          transform: { type: 'boxplot' }
        }

      ];
      var option = {
        title: {
          text: '婴儿头围标准表',
          left: 'left',
          textStyle:{
            color:'#fff',
            fontSize:16,
            fontFamily:'qingning'
          }
        },
            dataset: dataset,
        color:["#e5ad6a", "#fae789"],
        legend: {
          // top: '10%',
          right: '0',
          textStyle: {
            color: "#fff"
          },
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            // params = params[0];
            if (params.seriesName == '男孩') var result = params.marker + ' ' + params.name + '月龄<br/>标准：' + dataset[0].source[params.dataIndex][1] + 'cm ；<br/>范围：' + dataset[0].source[params.dataIndex][0] + '~' + dataset[0].source[params.dataIndex][2] + 'cm'
            else result = params.marker + ' ' + params.name + '月龄<br/>标准：' + dataset[1].source[params.dataIndex][1] + 'cm ；<br/>范围：' + dataset[1].source[params.dataIndex][0] + '~' + dataset[1].source[params.dataIndex][2] + 'cm'
            console.log(params);
            return result
          }
        },
        grid: {
          left: '10%',
          top: '20%',
          right: '10%',
          bottom: '15%'
        },
        xAxis: {
          type: 'category',
          // name: '月份',
          itemStyle:{
            color:'#fff'
          },
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: true
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        yAxis: {
          type: 'value',
          // name: 'cm',
          min: 0,
          max: 60,
          splitArea: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 20
          },
          {
            show: true,
            type: 'slider',
            top: '90%',
            xAxisIndex: [0],
            start: 0,
            end: 20
          }
        ],
        series: [
          {
            name: '男孩',
            type: 'boxplot',
            datasetIndex: 2,
            itemStyle:{
              color:"#e5ad6a"
            }
          },
          {
            name: '女孩',
            type: 'boxplot',
            datasetIndex: 3,
            itemStyle:{
              color:"#fae789"
            }
          }
        ]
      }
      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
      getchart.on('click', function (param) {
        console.log(param.data);
        that.$emit('radar_clicked', param.data.name);
      })
    }
  }
}
</script>

<style scoped>

</style>
