<template>
  <div id="pie2" :style="{width: '45vw', height: '55vh',marginTop:'30px',float:'left'}"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "pie2",
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {
      let that = this;
      let getchart = echarts.init(document.getElementById('pie2'));
      var option = {
        title: {
          // text: '五月孕期饮食',
          // subtext: 'Fake Data',
          // top:0,
          // left: 'left',
          fontFamily:'qingning',
          textStyle:{
            fontSize:30,
            marginBottom:20
          }

        },
        tooltip: {
          trigger: 'item'
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'left'
        // },
        color:['#9987ce', '#76da91', '#f8cb7f','#f89588','#7cd6cf','#9192ab','#7898e1','#efa666','#eddd86','#63b2ee'],
        series: [
          {
            name: '孕期饮食',
            type: 'pie',
            radius: '70%',
            data: [
              { value: 1, name: '叶酸补充剂 0.4mg ' },
              { value: 1, name: '加碘食盐 <6克 ' },
              { value: 2, name: '油 25-30克' },
              { value: 10, name: '奶类 300-500克 ' },
              { value: 2, name: '大豆/坚果 20克/10克 ' },
              { value: 5, name: '鱼禽蛋肉类 150-200克' },
              { value: 10, name: '蔬菜类 300-500克' },
              { value: 6, name: '水果类 200-400克' },
              { value: 9, name: '谷薯类  275-325克' },
              { value: 54, name: '水 1700-1900ml' }
            ],
            label:{
              fontSize: 16
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
      getchart.on('click',function (param){
        console.log(param.data);
        that.$emit('pie2_clicked',param.data.name);
      })
    }
  }
}
</script>

<style scoped>

</style>
