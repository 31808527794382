<template>
<div class="after_container">
  <div class="content">
<!--    <myNav></myNav>-->
    <tree v-if="tree" @tree_clicked="changeCard"></tree>
    <sunbrust v-if="sunBrust" @clicked="changeCard"></sunbrust>
    <radar1 v-if="radar" @radar_clicked="changeCard"></radar1>
    <card :data="data">
      <template v-slot:title>
        floral
      </template>
      <template v-slot:content>
      </template>
    </card>
    <span class="resource">{{resource}}</span>
  </div>

  <div class="aside" :style="marginRight">
    <span>欢迎光临产妇馆</span>
    <div class="plus" :style="right" @click="showAside">
      <span :style="rotate"></span>
    </div>
    <li  @click="showChart(index)" v-for="(item,index) in List" :key="index" >
      <span :style="'background:url('+item.img+') center center no-repeat'" style="background-size: contain"></span>
      <a href="#">{{item.name}}</a>
    </li>
  </div>
<!--  <div></div>-->
</div>
</template>

<script>
import sunbrust from "../echart/sunbrust";
import radar1 from "../echart/radar1";
import card from "../public_components/card";
import tree from "../echart/tree";
// import myNav from "../public_components/myNav";
export default {
  name: "after",
  data() {
    return {
      close: true,
      close1:true,
      sunBrust:true,
      tree:false,
      radar:false,
      marginRight:"margin-right: -15vw;",
      right:"right: 0;",
      rotate:"",
      flag: 0,
      resource: "数据来源于中国营养学会妇幼营养分会",
      data:{
        titleIcon:null,
        title:"动物血或肝脏",
        item1Icon:require('../../assets/img/yingyang.png'),
        item1Title:'营养要素',
        item1Content:'维生素A、铁元素、胆固醇、蛋白质等',
        item2Icon:require('../../assets/img/caipu.png'),
        item2Title:'推荐做法',
        item2Content:'每周吃<b style="text-shadow: 1px 1px darkgray">1~2次</b>，总量达<b style="text-shadow: 1px 1px darkgray">85g猪肝</b>或<b style="text-shadow: 1px 1px darkgray">40g鸡肝</b>;肝脏清洗去毒，<b style="text-shadow: 1px 1px darkgray">高温</b>焖煮',
        item3Icon:require('../../assets/img/haochu.png'),
        item3Title:'食用好处',
        item3Content:'预防<b style="text-shadow: 1px 1px darkgray">缺铁性贫血</b>、增强体质免疫力'
      },
      mentalData:[
        {
          titleIcon:null,
          title:"产后沮丧",
          item1Icon:require('../../assets/img/zhengzhuang.png'),
          item1Title:'常见症状',
          item1Content:'病人常会有一种<b>失落、哭泣、空虚</b>的惑觉，接着会产生<b>激动、失眠、焦虑、疲倦、头痛、胃口减退</b>的心理与生理症状。',
          item2Icon:require('../../assets/img/zhiliao.png'),
          item2Title:'缓解&治疗方法',
          item2Content:'治疗上仅需要心理上的支持与适当病情解说，因此给予产妇<b>情绪发泄</b>的机会、情绪支持与保证是很重要的。'
        },
        {
          titleIcon:null,
          title:"产后抑郁",
          item1Icon:require('../../assets/img/zhengzhuang.png'),
          item1Title:'常见症状',
          item1Content:'其表现与其他抑郁障碍相同，情绪低落、快感缺乏、悲伤哭泣、担心多虑、胆小害怕、烦躁不安、易激惹发火，严重时失去生活自理和照顾婴儿的能力，悲观绝望、自伤自杀。',
          item2Icon: require('../../assets/img/zhiliao.png'),
          item2Title:'缓解&治疗方法',
          item2Content:'一般不需要药物治疗,因此及时发现产妇抑郁症并进行适当的<b>心理干预</b>至关重要。'
        },
        {
          titleIcon:null,
          title:"产后精神病",
          item1Icon:require('../../assets/img/zhengzhuang.png'),
          item1Title:'常见症状',
          item1Content:'症状包括非常悲伤、沮丧、哭泣、孤独、焦虑、恐惧、易怒等，精神紊乱、急性幻觉和妄想、严重抑郁等',
          item2Icon: require('../../assets/img/zhiliao.png'),
          item2Title:'缓解&治疗方法',
          item2Content:'产后精神病需要立即<b>药物干预</b>，并且进行<b>心理干预</b>。'
        }
      ],
      eatData:[
        {
          titleIcon:null,
          title:"动物血或肝脏",
          item1Icon:require('../../assets/img/yingyang.png'),
          item1Title:'营养要素',
          item1Content:'维生素A、铁元素、胆固醇、蛋白质等',
          item2Icon:require('../../assets/img/caipu.png'),
          item2Title:'推荐做法',
          item2Content:'每周吃<b style="text-shadow: 1px 1px darkgray">1~2次</b>，总量达<b style="text-shadow: 1px 1px darkgray">85g猪肝</b>或<b style="text-shadow: 1px 1px darkgray">40g鸡肝</b>;肝脏清洗去毒，<b style="text-shadow: 1px 1px darkgray">高温</b>焖煮',
          item3Icon:require('../../assets/img/haochu.png'),
          item3Title:'食用好处',
          item3Content:'预防<b style="text-shadow: 1px 1px darkgray">缺铁性贫血</b>、增强体质免疫力'
        },
        {
          titleIcon:null,
          title:"薯类",
          item1Icon:require('../../assets/img/yingyang.png'),
          item1Title:'营养要素',
          item1Content:'β-胡萝卜素、维生素C和叶酸等',
          item2Icon:require('../../assets/img/caipu.png'),
          item2Title:'推荐做法',
          item2Content:'烧煮<b style="text-shadow: 1px 1px darkgray">蒸熟</b>、磨粉做<b style="text-shadow: 1px 1px darkgray">面食</b>',
          item3Icon:require('../../assets/img/haochu.png'),
          item3Title:'食用好处',
          item3Content:'有助于<b style="text-shadow: 1px 1px darkgray">抵抗氧化应激</b>对DNA的损伤，<b style="text-shadow: 1px 1px darkgray">清肠抗癌</b>'
        },
      ],
      huLiData:[
        {
          title:"恶露",
          // item1Title:'营养要素',
          item1Content:'产后最初3-4日为血性恶露，量多，色红。之后转为浆液恶露，色淡红，持续10日左右，产后2周转为白色。恶露总量250-500毫升，大约在产后4-6周排除干净。正常恶露有血腥味，但无臭味。若恶露异味、颜色污浊、带有块状物、甚至发烧、腹痛异常等情况时，应及时就医。',
        },
        {
          title:"身体清洁",
          // item1Title:'营养要素',
          item1Content:'要坚持天天洗脸、刷牙选用软毛牙刷,勤换衣物,尤其是保持乳房的清洁。早晚梳头、每周坚持洗头。淋浴洗澡，时间不宜过长,每次5~10分钟即可。',
        },
        {
          title:"保持清洁",
          // item1Title:'营养要素',
          item1Content:'每天用温开水或1：5000高锰酸钾水溶液冲洗2次，从前往后冲洗。每次便后用新洁尔灭消毒棉擦拭，冲洗外阴，要有专用冲洗器具。',
        },
        {
          title:"保持干燥",
          // item1Title:'营养要素',
          item1Content:'卫生巾应经常更换，内衣、内裤要勤洗勤换。',
        },
        {
          title:"坚持做盆底运动",
          // item1Title:'营养要素',
          item1Content:'不要坐的时间太久，以免会阴部发生肿胀，影响愈合。',
        },
        {
          title:"保持大便通畅",
          // item1Title:'营养要素',
          item1Content:'避免因排便用力过度而导致伤口剧痛甚至切口裂开。',
        },
        {
          title:"注意伤口肿胀、疼痛",
          // item1Title:'营养要素',
          item1Content:'如会阴切口在产后24小时以后出现肿胀、疼痛、硬结，可局部采用红外线照射，以促进会阴部血液循环，利于切口愈合。',
        },
        {
          title:"注意伤口感染",
          // item1Title:'营养要素',
          item1Content:'会阴伤口肿胀、感染等情况下，可在医生的指导下服用抗生素，或局部采用硫酸镁、芒硝等外敷。',
        },
      ],
      List:[
        {
          name:"膳食馆",
          // margin:"margin-right: 0",
          img:require('../../assets/after/location1.png')
        },
        {
          name:"护理中心",
          // margin:"",
          img:require('../../assets/after/location2.png')
        },
        {
          name:"心理观察室",
          // margin:""
          img:require('../../assets/after/location3.png')
        }
      ],
    }
  },
  components:{
    // myNav,
    sunbrust,
    tree,
    radar1,
    // show_chart,
    // show_chart2,
    card
  },
  methods: {
    showAside(){
      if (!this.rotate){
        this.marginRight = "margin-right: 0;"
        this.right = "right: 15vw;"
        this.rotate = "transform: rotate(45deg);"
      } else{
        this.marginRight = "margin-right: -15vw"
        this.right = "right: 0;"
        this.rotate = ""
      }

    },
    closeChart(flag) {
      // console.log(flag);
      this.close = flag;
      this.close1 = flag;
      // for (let i = 0 ; i < this.List.length ; i++){
      //   this.List[i].margin = "";
      // }
    },
    chartShow() {
      this.close1 = false;
      this.data = this.mentalData[0];
    },
    showChart(index) {
      // let that = this;
      // this.close = false;
      this.showAside();
      console.log(index);
      if (index == 0) {
        this.sunBrust = true;
        this.radar = false;
        this.tree = false;
        this.data = this.eatData[0];
        this.resource = "数据来源于中国营养学会妇幼营养分会";
      } else if (index == 1){
        this.sunBrust = false;
        this.tree = true;
        this.radar = false;
        this.data = this.huLiData[0];
        this.resource = "数据来源于中国疾病预防控制中心妇幼保健中心";
      } else {
        this.sunBrust = false;
        this.radar = true;
        this.tree = false;
        this.data = this.mentalData[0];
        this.resource = "数据来源于百度百科"
      }
      // this.clockShow = !this.clockShow;
      this.List[index].margin = "margin-right: 0;";
      for (let i = 0; i < this.List.length; i++) {
        if (i != index) this.List[i].margin = "";
      }
    },
    changeCard(name) {
      let that = this;
      if (this.radar) {
        switch (name) {
          case '产后沮丧':
            that.data = that.mentalData[0];
            break;
          case '产后抑郁':
            that.data = that.mentalData[1];
            break;
          case '产后精神病':
            that.data = that.mentalData[2];
            break;
        }
      } else if (this.sunBrust){
        switch (name) {
          case '动物血或肝脏':
            that.data = that.eatData[0];
            break;
          case '薯类':
            that.data = that.eatData[1];
            break;
        }
      } else {
        switch (name){
          case '恶露':
            that.data = that.huLiData[0];
            break;
          case '身体清洁':
            that.data = that.huLiData[1];
            break;
          case '保持清洁':
            that.data = that.huLiData[2];
            break;
          case '保持干燥':
            that.data = that.huLiData[3];
            break;
          case '坚持做盆底运动':
            that.data = that.huLiData[4];
            break;
          case '保持大便通畅':
            that.data = that.huLiData[5];
            break;
          case '注意伤口肿胀、疼痛':
            that.data = that.huLiData[6];
            break;
          case '注意伤口感染':
            that.data = that.huLiData[7];
            break;
        }
      }
    }
  }
}
</script>

<style scoped>
a{
  text-decoration: none;
  color: black;
}
.after_container{
  width: 100%;
  height: 100vh;
  background-color: #27869c;
  position: relative;
  overflow: hidden;
  font-family: qingning;
}
.after_container>.content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /*background: url("../../assets/after/mother.png") center bottom no-repeat;*/
  /*background-size: 50%;*/
  /*position: absolute;*/
  /*top: 0;*/
}
.aside{
  width: 15vw;
  height: 100vh;
  background-color: rgba(252, 253, 253, 0.5);
  /*border-radius: 10px;*/
  /*border-bottom-left-radius: 10px;*/
  /*border-top-left-radius: 10px;*/
  /*border-top: 3px solid #47778b;*/
  /*border-bottom: 3px solid #47778b;*/
  /*border-left: 3px solid #47778b;*/
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /*margin-right: -100%;*/
  /*overflow: hidden;*/
  z-index: 99;
  transition: all 1s ease-in-out;
}
.aside>span{
  display: inline-block;
  position: absolute;
  top: 100px;
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: bolder;
  /* margin: 0 auto; */
  color: white;
}
.plus{
  /*content: "";*/
  display: inline-block;
  width: 50px;
  height: 30px;
  position: fixed;
  /*right: 0;*/
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: rgba(252, 253, 253, 0.5);
  transition: all 1s ease-in-out;
  background-position: center center;
  /*transform: rotate(45deg);*/
  /*background-repeat: no-repeat;*/
  /*background-size: 35%;*/
}
.plus>span{
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px;
  background: url("../../assets/after/plus.png") center center no-repeat;
  background-size: contain;
  transition: all 1s ease-in-out;
  /*transform: rotate(45deg);*/
}
li{
  margin: 0;
  list-style-type: none;
  padding: 0;
}
.aside>li{
  width: 90%;
  height: 25px;
  font-size: 20px;
  line-height: 25px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-right: -80%;*/
  /*background-color: rgba(215,215,215,0.7);*/
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s cubic-bezier(.74,.2,.37,1.07);
}
.aside>li:hover{
  /*margin-right: 0;*/
}
.aside>li>span{
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 20px;
  /*background: #27869c;*/
}
.aside>li>a{
  width: 55%;
  text-align: left;
  font-weight: bolder;
}
.resource{
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: #fbffff;
}
</style>
