<template>
  <div class="body_container">
    <div class="pregnant">
      <!--      <transition name="nav">-->

      <!--      </transition>-->

      <li v-for="index in 10" :key="index"></li>
    </div>
    <div class="body_content">
      <div v-for="count in 10" :key="count" :style="stop" @mouseover="stopAnimation" @mouseleave="startAnimation" @click="showMore(count)"></div>
<!--      <div v-for="(item,index) in list" :key="index" :style="'background:url('+item.img+') center center no-repeat;background-size:cover'"></div>-->

    </div>
    <div class="ruler">
      <div v-for="count in 10" :key="count" :style="stop">{{ count }}月</div>
    </div>
    <div class="more" v-show="small" >
      <div class="items">
        <div class="item" @click="showDetail(1)">产检要点</div>
        <div class="item" @click="showDetail(2)">饮食要点</div>
      </div>
      <div class="back" @click="closeMore">
        <span></span>
      </div>
      <div class="pregnant" v-show="small" :style="{background: 'url('+monthBackground+') center center no-repeat'}">
        <!--      <transition name="nav">-->

        <!--      </transition>-->

        <li v-for="index in 10" :key="index"></li>
      </div>
      <div class="detail" :style="detail">
        <span class="title" v-show="titleShow">{{title}}</span>
        <span class="icon" @click="showDetail" :style="rotate"></span>
        <div class="content" v-html="content" v-show="!charts"></div>
        <div class="charts" v-show="charts">
          <pie2 @pie2_clicked="showText"></pie2>
          <div class="text" v-html="content"></div>
        </div>
        <span class="resource">{{resource}}</span>
      </div>
    </div>
      </div>

</template>
<script>
import pie2 from "./echart/pie2";
export default {
  name: "FenMian2",
  components:{
    pie2
  },
  data() {
    return {
      stop:"",
      list:[],
      small:false,
      charts:false,
      resource:"数据来源于中华人民共和国国家卫生健康委员会",
      title:"孕五月饮食",
      titleShow:false,
      month:0,
      rotate:"",
      detail:"",
      content:"1.按照初诊或复诊要求进行相应检查。<br/>" +
          "2.了解胎动出现时间，绘制妊娠图。<br/>" +
          "3.基本检查项目：体重、血压、宫高、腹围、胎心、血常规、尿常规,超声筛查胎儿畸形。建议检查项目：唐氏综合症筛查。<br/>" +
          "4.提倡适量运动，预防及纠正贫血。有口腔疾病的孕妇，建议到口腔科治疗。<br/>" +
          "5.筛查危险因素，进行监测、治疗妊娠合并症及并发症，必要时转诊。<br/>",
      url1: require('../assets/pregnant/1.png'),
      url2: require('../assets/pregnant/2.png'),
      url3: require('../assets/pregnant/3.png'),
      url4: require('../assets/pregnant/4.png'),
      url5: require('../assets/pregnant/5.png'),
      url6: require('../assets/pregnant/6.png'),
      url7: require('../assets/pregnant/7.png'),
      url8: require('../assets/pregnant/8.png'),
      url9: require('../assets/pregnant/9.png'),
      url10: require('../assets/pregnant/10.png')
    }
  },
  computed: {
    monthBackground: function () {
      switch (this.month) {
        case 1:
          return this.url1
        case 2:
          return this.url2
        case 3:
          return this.url3
        case 4:
          return this.url4
        case 5:
          return this.url5
        case 6:
          return this.url6
        case 7:
          return this.url7
        case 8:
          return this.url8
        case 9:
          return this.url9
        default:
          return this.url10
      }
    }
  },
  mounted() {

  },
  methods: {
    stopAnimation(){
      this.stop = "animation-play-state: paused;";
    },
    startAnimation(){
      if (!this.small)
        this.stop = "animation-play-state: running;";
    },
    showMore(index){
      console.log(2);
      this.stop = "animation-play-state: paused;";
      this.month = index;
      this.small = true;
      // this.startAnimation = function (){}
    },
    closeMore(){
      console.log(1);
      this.stop = "";
      // this.month = index;
      this.small = false;
      this.stop = "animation-play-state: running;";
      // this.startAnimation = function (){this.stop = "";}
    },
    showDetail(flag){
      let that = this;
      if (this.detail == ""){
        this.detail = "bottom:0;animation-play-state:paused";
        this.rotate = "transform:rotate(180deg);"
        this.titleShow = true;
      } else {
        this.detail = "";
        this.rotate = "";
        setTimeout(function (){
          that.titleShow = false;
        },500)
      }
      if (this.month == 5){
        if (flag == 1) {
          that.resource = "数据来源于中华人民共和国国家卫生健康委员会";
          that.title = "孕五月产检要点";
            that.charts = false;
            that.content = "1. 按照初诊或复诊要求进行相应检查。<br/>" +
                "2. 了解<b>胎动</b>出现时间，绘制妊娠图。<br/>" +
                "3. 基本检查项目：<b>体重、血压、宫高、腹围、胎心、血常规、尿常规,超声筛查胎儿畸形</b>。<br/>" +
                "4. 建议检查项目：唐氏综合症筛查。<br/>" +
                "5. 提倡<b>适量运动</b>，预防及纠正<b>贫血</b>。有口腔疾病的孕妇，建议到口腔科治疗。<br/>" +
                "6. 筛查危险因素，进行监测、治疗<b>妊娠合并症及并发症</b>，必要时转诊。<br/>";


        } else if (flag == 2){
          that.resource = "数据来源于中国营养学会妇幼营养分会"
          that.title = "孕五月饮食要点";
            that.charts = true;
            that.content = "营养要素：<br/>" +
                "维生素A、维生素C、B族维生素、植物纤维、降糖物质等<br/>" +
                "推荐做法：<br/>" +
                "不宜与酒、萝卜同食，炖炒时<b>多放油</b><br/>" +
                "食用好处：<br/>" +
                "预防<b>感冒、肥胖</b>，增强胎儿<b>抗病能力</b>和<b>视力发育</b><br/>";


        }
      }
    },
    showText(name){
      let that = this;
      switch (name){
        case '蔬菜类 300-500克':
          that.content = "营养要素：<br/>" +
              "维生素A、维生素C、B族维生素、植物纤维、降糖物质等<br/>" +
              "推荐做法：<br/>" +
              "不宜与酒、萝卜同食，炖炒时多放油<br/>" +
              "食用好处：<br/>" +
              "预防感冒、肥胖，增强胎儿抗病能力和视力发育<br/>"
           break;
        case '鱼禽蛋肉类 150-200克':
          that.content = "营养要素：<br/>" +
              "蛋白质、脂肪、碳水化合物、矿物质等<br/>" +
              "推荐做法：<br/>" +
              "清蒸最好，剔除虾头，煮熟煮透<br/>" +
              "不宜与冬瓜、红枣、南瓜、黄豆、猪肉鸡肉及含维生素C的食物同食<br/>" +
              "食用好处：<br/>" +
              "促进胎儿骨骼生长发育<br/>"
      }
    }
  },
  // components: {}
};
</script>
<style scoped>
.body_container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  overflow: hidden;
  background-image: linear-gradient(#27869c, #27869c, #629dba, #66bfcb);
  font-family: 'qingning';
}
.body_content{
  width: 100%;
  height: 100vh;
  /*display: flex;*/
  /*align-items: flex-end;*/
  /*justify-content: flex-start;*/
  /*flex-wrap: nowrap;*/
  position: relative;
  overflow: hidden;
}
.body_content>div{
  position: absolute;
  bottom: 0;
  left: -25px;
  width: 20px;
  height: 20px;
  /*margin-left: 20px;*/
  /*background-color: #2079a3;*/
  /*background-size: cover;*/
}

.body_content>div:first-child{
  animation: big 50s linear infinite;
  background: url("../assets/pregnant/1.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(2){
  animation: big 50s linear infinite 5s;
  background: url("../assets/pregnant/2.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(3){
  animation: big 50s linear infinite 10s;
  background: url("../assets/pregnant/3.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(4){
  animation: big 50s linear infinite 15s;
  background: url("../assets/pregnant/4.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(5){
   animation: big 50s linear infinite 20s;
  background: url("../assets/pregnant/5.png") center center no-repeat;
  background-size: contain;
 }
.body_content>div:nth-child(6){
  animation: big 50s linear infinite 25s;
  background: url("../assets/pregnant/6.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(7){
  animation: big 50s linear infinite 30s;
  background: url("../assets/pregnant/7.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(8){
   animation: big 50s linear infinite 35s;
  background: url("../assets/pregnant/8.png") center center no-repeat;
  background-size: contain;
 }
.body_content>div:nth-child(9){
  animation: big 50s linear infinite 40s;
  background: url("../assets/pregnant/9.png") center center no-repeat;
  background-size: contain;
}
.body_content>div:nth-child(10){
  animation: big 50s linear infinite 45s;
  background: url("../assets/pregnant/10.png") center center no-repeat;
  background-size: contain;
}
@keyframes big {
  0%{
    width: 5px;
    height: 5px;
    left: -2%;
  }
  10%{
    width: 45px;
    height: 45px;
    left: 4%;
  }
  20%{
    width: 85px;
    height: 85px;
    left: 10%;
  }
  30%{
    width: 125px;
    height: 125px;
    left: 16%;
  }
  40%{
    width: 165px;
    height: 165px;
    left: 28%;
  }
  50%{
    width: 405px;
    height: 405px;
    left: 43%;
  }
  60%{
    width: 165px;
    height: 165px;
    left: 72%;
  }
  70%{
    width: 125px;
    height: 125px;
    left: 84%;
  }
  80%{
    width: 85px;
    height: 85px;
    left: 90%;
  }
  90%{
    width: 45px;
    height: 45px;
    left: 96%;
  }
  100%{
    width: 5px;
    height: 5px;
    left: 102%;
  }
}
.stop{
  animation-play-state: paused;
  animation-duration: 60s;
}
.ruler{
  width: 100%;
  height: 100px;
  margin-bottom: 50px;
  position: relative;
  box-shadow: 2px 2px 5px #529faa;
  background-color: #1f91c7;
}
.ruler>div{
  width: 40px;
  height: 100%;
  line-height: 85.65px;
  font-size: 20px;
  color: white;
  text-shadow: 2px 2px 2px darkgrey;
  text-align: center;
  /*writing-mode: vertical-lr;!*从左向右 从右向左是 writing-mode: vertical-rl;*!*/
  /*writing-mode: tb-lr;!*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*!*/
  position: absolute;
  bottom: 0;
  left: -35px;
}
.ruler>div:first-child{
  animation: big2 50s linear infinite;
}
.ruler>div:nth-child(2){
  animation: big2 50s linear infinite 5s;
}
.ruler>div:nth-child(3){
  animation: big2 50s linear infinite 10s;
}
.ruler>div:nth-child(4){
  animation: big2 50s linear infinite 15s;
}
.ruler>div:nth-child(5){
  animation: big2 50s linear infinite 20s;
}
.ruler>div:nth-child(6){
  animation: big2 50s linear infinite 25s;
}
.ruler>div:nth-child(7){
  animation: big2 50s linear infinite 30s;
}
.ruler>div:nth-child(8){
  animation: big2 50s linear infinite 35s;
}
.ruler>div:nth-child(9){
  animation: big2 50s linear infinite 40s;
}
.ruler>div:nth-child(10){
  animation: big2 50s linear infinite 45s;
}
@keyframes big2 {
  0%{
    width: 30px;
    /*height: 5px;*/
    left: -2%;
  }
  10%{
    width: 45px;
    /*height: 45px;*/
    left: 4%;
  }
  20%{
    width: 85px;
    /*height: 85px;*/
    left: 10%;
  }
  30%{
    width: 125px;
    /*height: 125px;*/
    left: 16%;
  }
  40%{
    width: 165px;
    /*height: 165px;*/
    left: 28%;
  }
  50%{
    width: 405px;
    /*height: 405px;*/
    left: 43%;
  }
  60%{
    width: 165px;
    /*height: 165px;*/
    left: 72%;
  }
  70%{
    width: 125px;
    /*height: 125px;*/
    left: 84%;
  }
  80%{
    width: 85px;
    /*height: 85px;*/
    left: 90%;
  }
  90%{
    width: 45px;
    /*height: 45px;*/
    left: 96%;
  }
  100%{
    width: 30px;
    /*height: 5px;*/
    left: 102%;
  }
}
.more{
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 9;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  background-image: linear-gradient(#1a4963, #1a4963, #1a4963, #1a4963, #faea6a);
  overflow: hidden;
}
.items{
  width: 90%;
  height: 20vh;
  position: relative;
  margin: 0 auto;
  /*display: flex;*/
  /*justify-content: space-between;*/
  margin-top: 30vh;
}
.item{
  width: 20%;
  height: 90%;
  margin: 0 30px;
  color: white;
  font-size: 28px;
  line-height: 18vh;
  z-index: 99999;
  /*margin: 30px;*/
  bottom: 30px;
  border-radius: 10px;
  background-color: #408195;
  position: absolute;

}
.item:first-child{
  left: 0;
  animation: itemMove 4s cubic-bezier(0,-0.96,.41,.6) infinite;
}
.item:nth-child(2){
  right: 0;
  animation: itemMove2 4s cubic-bezier(0,-0.96,.41,.6) infinite;
}
@keyframes itemMove {
  0%{
    bottom: 30px;
    background-color: #408195;
    box-shadow: none;
  }
  5%{
    bottom: 30px;
    background-color: #6cdafc;
    box-shadow: -5px 0 2px rgba(255, 255, 255, .1), 0 -5px 2px rgba(255, 255, 255, .1), 5px 0 2px rgba(255, 255, 255, .1), 0 5px 2px rgba(255, 255, 255, .1);
  }
  10%{
    bottom: 30px;
    background-color: #408195;
    box-shadow: none;
  }
  12.5%{
    bottom: 30px;
    background-color: #408195;
    box-shadow: none;
  }
  15%{
    bottom: 20px;
    background-color: #408195;
    box-shadow: none;
  }
  17.5%{
    bottom: 10px;
    background-color: #408195;
    box-shadow: none;
  }
  20%{
    bottom: 20px;
    background-color: #408195;
    box-shadow: none;
  }
  22.5%,100%{
    bottom: 30px;
    background-color: #408195;
    box-shadow: none;
  }
}
@keyframes itemMove2 {
  0% {
    bottom: 10px;
    background-color: #408195;
    box-shadow: none;
  }
  5% {
    bottom: 10px;
    background-color: #6cdafc;
    box-shadow: -5px 0 2px rgba(255, 255, 255, .1), 0 -5px 2px rgba(255, 255, 255, .1), 5px 0 2px rgba(255, 255, 255, .1), 0 5px 2px rgba(255, 255, 255, .1);
  }
  10% {
    bottom: 10px;
    background-color: #408195;
    box-shadow: none;
  }
  12.5% {
    bottom: 10px;
    background-color: #408195;
    box-shadow: none;
  }
  15% {
    bottom: 20px;
    background-color: #408195;
    box-shadow: none;
  }
  17.5% {
    bottom: 30px;
    background-color: #408195;
    box-shadow: none;
  }
  20% {
    bottom: 20px;
    background-color: #408195;
    box-shadow: none;
  }
  22.5%, 100% {
    bottom: 10px;
    background-color: #408195;
    box-shadow: none;
  }
}
.pregnant{
  position: absolute;
  overflow: hidden;
  /*使气泡背景充满整个屏幕*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(.74,.2,.37,1.07);
  /*background: url("../../assets/pregnant/6.png") center center no-repeat;*/
  background-size: contain;
}
.pregnant>li{
  border-radius: 50%;
  position: absolute;
  /*bottom 的设置是为了营造出气泡从页面底部冒出的效果；*/
  bottom: -160px;
  /*// 默认的气泡大小；*/
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  list-style: none;
  /*// 使用自定义动画使气泡渐现、上升和翻滚；*/
  animation: square 15s infinite;
  transition-timing-function: linear;
}
.pregnant>li:nth-child(1){
  left: 10%;
}
.pregnant>li:nth-child(2){
  left: 20%;
  width: 90px;
  height: 90px;
  animation-delay: 2s;
  animation-duration: 7s;
}
.pregnant>li:nth-child(3){
  left: 25%;
  animation-delay: 4s;
}
.pregnant>li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-duration: 8s;
  background-color: rgba(255, 255, 255, 0.3);
}
.pregnant>li:nth-child(5){
  left: 70%;
}
.pregnant>li:nth-child(6){
  left: 80%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.2);
}
.pregnant>li:nth-child(7){
  left: 32%;
  width: 160px;
  height: 160px;
  animation-delay: 2s;
}
.pregnant>li:nth-child(8){
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
  animation-duration: 15s;
}
.pregnant>li:nth-child(9){
  left: 25%;
  width: 10px;
  height: 10px;
  animation-delay: 2s;
  animation-duration: 12s;
  background-color: rgba(255, 255, 255, 0.3);
}
.pregnant>li:nth-child(10){
  left: 85%;
  width: 160px;
  height: 160px;
  animation-delay: 5s;
}
@keyframes square {
  0% {
    opacity: 0.5;
    transform: translateY(0px) rotate(45deg);
  }
  25% {
    opacity: 0.75;
    transform: translateY(-400px) rotate(90deg)
  }
  50% {
    opacity: 1;
    transform: translateY(-600px) rotate(135deg);
  }
  100% {
    opacity: 0;
    transform: translateY(-1000px) rotate(180deg);
  }
}
.back{
  display: inline-block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 40px;
  left: 60px;
  z-index: 9999;
  background: url("../assets/pregnant/back.png") center center no-repeat;
  background-size: contain;
  /*animation: back 2s linear infinite;*/
}
.back:hover{
  animation: opacity 0.5s ease-in-out;
}
@keyframes opacity {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
.back>span{
  display: inline-block;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 40px;
  left: 60px;
  background-color: #1a4963;
  animation: back 8s ease-in infinite;
  animation-fill-mode: forwards;
  /*animation-direction: reverse;*/
  /*opacity: 0;*/
}
@keyframes back {
  0%{
    height: 50px;
  }
  2.5%{
    height: 37.5px;
  }
  5%{
    height: 25px;
  }
  7.5%{
    height: 12.5px;
  }
  10%,100%{
    height: 0;
  }
}
.detail{
  width: 100%;
  height: 70vh;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  bottom: -60vh;
  transition: all 0.5s linear;
  animation: detail 5s linear infinite;
}
.title{
  font-size: 30px;
  font-family: qingning;
  font-weight: bolder;
  margin-left: 80px;
  position: absolute;
  top: 30px;
}
.content{
  text-align: left;
  /*text-indent: 2em;*/
  line-height: 50px;
  font-size: 25px;
  width: 75%;
  /*height: 100%;*/
  margin: 0 auto;
}
.charts{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-around;
  /*align-items: center;*/
}
.text{
  width: 42%;
  float: right;
  text-align: left;
  line-height: 50px;
  margin: 54px 20px;
  margin-right: 150px;
  /* left: 0; */
  /* margin: auto; */
  font-size: 25px;
  /*line-height: 30px;*/
  display: inline-block;
}
.detail>.icon{
  position: absolute;
  top: 20px;
  right: 40px;
  display: inline-block;
  width: 64px;
  height: 64px;
  background: url("../assets/pregnant/arrow.png") center center no-repeat;
  background-size: contain;
  transition: all 0.5s linear;
}
@keyframes detail {
  0%{
    background-color: white;
  }
  5%{
    background-color: white;
  }
  10%{
    background-color: white;
  }
  12.5%{
    background-color: rgba(185, 181, 100, 1);
  }
  15%{
    background-color: rgb(241, 238, 175);
  }
  17.5%,100%{
    background-color: rgba(255, 255, 255, 0.9);
  }
}
.resource{
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: darkgrey;
}
</style>
