<template>
  <div class="echart" id="echart-line" :style="{float:'left',margin:'10vh auto',marginLeft:'5vw',width: '40vw', height: '80vh'}"></div>
</template>

<script>

import * as echarts from  "echarts";
export default {
  name:"sunbrust",
  props:{
    List:{
      type: Array,
      default:()=>[]
      // default: null
    }
  },
  data() {
    return {
      // name:'张雪',
      // xData: ['2020-02', '2020-03', '2020-04', '2020-05'],
      // yData: [30, 132, 80, 134],
      data :[
        {
          name: '加碘食盐',
          value:1,
          itemStyle: {
            color: '#ddf1b6'
          },
        },
        {
          name: '油',
          value:2,
          itemStyle: {
            color: '#f3d6bb'
          },
        },
        {
          name: '奶类',
          value:7,
          itemStyle: {
            color: '#f6aebe'
          },
          children: [
            {
              name: '酸奶',
              value:3,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '奶酪',
              value:2,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '干酪',
              value:2,
              itemStyle: {
                color: '#ff7581'
              },
            },
          ]
        },
        {
          name: '大豆/坚果',
          value:4,
          itemStyle: {
            color: '#f8a0cd'
          },
          children: [
            {
              name: '青豆',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '黄豆',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '小豆',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '豌豆',
              value:1,
              itemStyle: {
                color: '#fd5a92'
              },
            }
          ]
        },
        {
          name: '鱼禽蛋肉类',
          value:6,
          itemStyle: {
            color: '#f386da'
          },
          children: [
            {
              name: '瘦肉禽肉',
              value:2,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '鱼虾类',
              value:2,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '蛋类',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '动物血或肝脏',
              value:1,
              itemStyle: {
                color: '#fd5a92'
              },
            }
          ]
        },
        {
          name: '蔬菜类',
          value:11,
          itemStyle: {
            color: '#fc5bd9'
          },
          children: [
            {
              name: '芹菜',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '菠菜',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '橄榄菜',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '莲藕',
              value:1,
              itemStyle: {
                color: '#fd5a92'
              },
            },
            {
              name: '黄豆芽',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '南瓜',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '菜花',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '胡萝卜',
              value:2,
              itemStyle: {
                color: '#fd5a92'
              },
            },
            {
              name: '海带',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '莴笋',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            }
          ]
        },
        {
          name: '水果类',
          value:6,
          itemStyle: {
            color: '#ff4bed'
          },
          children: [
            {
              name: '猕猴桃',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '榴莲',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '苹果',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '木瓜',
              value:1,
              itemStyle: {
                color: '#fd5a92'
              },
            },
            {
              name: '樱桃',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
            {
              name: '桃子',
              value:1,
              itemStyle: {
                color: '#f59dca'
              },
            },
            {
              name: '菠萝',
              value:1,
              itemStyle: {
                color: '#ff7581'
              },
            },
            {
              name: '香蕉',
              value:1,
              itemStyle: {
                color: '#fd5a92'
              },
            },
            {
              name: '荔枝',
              value:1,
              itemStyle: {
                color: '#97b3f3'
              },
            },
          ]
        },
        {
          name: '谷薯类',
          value:7,
          itemStyle: {
            color: '#e65cf3'
          },
          children: [
            {
              name: '全谷物和杂豆',
              value:3,
              itemStyle: {
                color: '#9289fd'
              },
            },
            {
              name: '薯类',
              value:4,
              itemStyle: {
                color: '#f874f1'
              },
            }
          ]
        },
        {
          name: '水',
          value:58,
          itemStyle: {
            color: '#00eaff'
          },
        }
      ],
      dataList: this.List
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    console.log(this.data);
    console.log(this.dataList);
    console.log(this.List);
    this.initChart(this.data)
  },
  methods:{
    initChart(data) {
      let  that = this;
      let getchart = echarts.init(document.getElementById('echart-line'));
      var  option = {
        title: {
          // text: 'WORLD COFFEE RESEARCH SENSORY LEXICON',
          // subtext: 'Source: https://worldcoffeeresearch.org/work/sensory-lexicon/',
          textStyle: {
            fontSize: 14,
            align: 'center'
          },
          subtextStyle: {
            align: 'center',
          },
        },
        series:{
          type: 'sunburst',
          data: data,
          radius: [0, '95%'],
          sort: undefined,
          label:{
            textStyle: {
              fontSize: 16,
              // fontWeight:'bolder',
              color:"#000"
            }
          },
          itemStyle:{
            // color:'#ef4242'
          },
          emphasis: {
            focus: 'ancestor'
          },
          levels: [
            // {},
            {
              r0: '15%',
              r: '35%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                rotate: 'tangential',
                fontFamily:'qingning',
              }
            },
            {
              r0: '25%',
              r: '65%',
              label: {
                align: 'right',
                fontFamily:'qingning'
              }
            },
            {
              r0: '65%',
              r: '68%',
              label: {
                position: 'outside',
                padding: 3,
                silent: false,
                fontFamily:'qingning',
                color:"#fff"
              },
              itemStyle: {
                borderWidth: 3
              }
            }
          ]
        }
      }
      getchart.setOption(option);
      //随着屏幕大小调节图表

      window.addEventListener("resize", () => {
        getchart.resize({
          width: this.width,
          height: this.height
        });
      });
      getchart.on('click',function (param){
        console.log(param.data);
        that.$emit('clicked',param.data.name);
      })
    },

  }

}
</script>

<style scoped>
/*.echart{*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
/*.echart>div{*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
/*.echart>div>canvas{*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/
</style>
