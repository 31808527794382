<template>
  <div id="radar1" :style="{float:'left',margin:'10vh ',width: '45vw', height: '80vh'}"></div>
</template>

<script>
import * as echarts from  "echarts";
export default {
  name: "radar1",
  data() {
    return {
      dataBJ:[
        [154, 117, 157, 3.05, 92, 58, 26],
        [234, 185, 230, 4.09, 123, 69, 27],
        [160, 120, 186, 2.77, 91, 50, 28],
        [134, 96, 165, 2.76, 83, 41, 29],
      ],
      dataGZ: [
        [93, 77, 104, 1.165, 53, 7, 23],
        [99, 130, 227, 3.97, 55, 15, 24],
        [146, 84, 139, 1.094, 40, 17, 25],
        [113, 108, 137, 1.481, 48, 15, 26],
      ],
      dataSH: [
        [93, 68, 96, 1.05, 79, 29, 28],
        [188, 143, 197, 1.66, 99, 51, 29],
        [174, 131, 174, 1.55, 108, 50, 30],
        [187, 143, 201, 1.39, 89, 53, 31]
      ]
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart(this.dataBJ,this.dataGZ,this.dataSH)
  },
  methods: {

    initChart(dataBJ, dataGZ, dataSH) {
      let that = this
      let getchart = echarts.init(document.getElementById('radar1'));
      const lineStyle = {
        width: 1,
        opacity: 0.5
      };
      var option = {
        // backgroundColor: 'rgba(20,106,153,0.75)',
        // legend: {
        //   bottom: 5,
        //   data: ['产后沮丧', '产后抑郁', '产后精神病'],
        //   itemGap: 20,
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: 14
        //   },
        //   selectedMode: 'single'
        // },
        legend:{
            bottom: 5,
            itemGap: 20,
            textStyle: {
              color: '#fff',
              fontSize: 14,
              fontFamily:"qingning",
            },
            // selectedMode: 'single',
          data:[{                    // 图例的数据数组。数组项通常为一个字符串，每一项代表一个系列的 name（如果是饼图，也可以是饼图单个数据的 name）。图例组件会自动根据对应系列的图形标记（symbol）来绘制自己的颜色和标记，特殊字符串 ''（空字符串）或者 '\n'（换行字符串）用于图例的换行。
            name: '产后沮丧',                 // 图例项的名称，应等于某系列的name值（如果是饼图，也可以是饼图单个数据的 name）。
            icon: 'rect',               // 图例项的 icon。
            textStyle: {                // 图例项的文本样式。
              color: 'rgb(255,253,113)',
              fontWeight: 'bold'		// 文字字体的粗细，可选'normal'，'bold'，'bolder'，'lighter'
            },
            itemStyle:{
              color: 'rgb(255,253,113)',
            }
          },{
            name: '产后抑郁',
            icon: 'rect',
            textStyle: {
              color: 'rgba(179,228,161,1)',
              fontWeight: 'bold'		// 文字字体的粗细，可选'normal'，'bold'，'bolder'，'lighter'
            },
            itemStyle: {
              color: 'rgba(179,228,161,1)',
            }
          }, {
            name: '产后精神病',
            icon: 'rect',
            textStyle: {
              color: 'rgba(0,255,247,1)',
              fontWeight: 'bold'		// 文字字体的粗细，可选'normal'，'bold'，'bolder'，'lighter'
            },
            itemStyle: {
              color: 'rgba(0,255,247,1)',
            }
          }]
        },
        radar: {
          indicator: [
            { name: '发生概率', max: 100 },
            { name: '严重程度', max: 5 },
            { name: '最早开始时间', max: 7 },
            { name: '最晚开始时间', max: 365 },
            { name: '最短持续时间', max: 30 },
            { name: '最长持续时间', max: 365 },
          ],
          shape: 'circle',
          splitNumber: 5,
          axisName: {
            color: 'rgb(255,253,113)',
            fontSize: 20,
            fontFamily:"qingning",
          },
          splitLine: {
            lineStyle: {
              color: [
                // 'rgba(238, 197, 102, 0.5)',
                // 'rgba(238, 197, 102, 0.6)',
                // 'rgba(238, 197, 102, 0.7)',
                // 'rgba(238, 197, 102, 0.8)',
                // 'rgba(238, 197, 102, 0.9)',
                'rgba(234,186,34,0.7)'
              ].reverse(),
              width:3
            }
          },
          splitArea: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(232,174,30,0.7)',
              width:3
            }
          }
        },
        series: [
          {
            name: '雷达图',             // 系列名称,用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
            type: 'radar',              // 系列类型: 雷达图
            itemStyle: {                // 折线拐点标志的样式。
              normal: {                   // 普通状态时的样式
                lineStyle: {
                  width: 1
                },
                opacity: 0.2
              },
              emphasis: {                 // 高亮时的样式
                lineStyle: {
                  width: 5
                },
                opacity: 1
              }
            },
            data: [{                    // 雷达图的数据是多变量（维度）的
              name: '产后沮丧',                 // 数据项名称
              value: [85, 1, 1, 4, 7, 14],        // 其中的value项数组是具体的数据，每个值跟 radar.indicator 一一对应。
              symbol: 'circle',                   // 单个数据标记的图形。
              symbolSize: 5,                      // 单个数据标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
              label: {                    // 单个拐点文本的样式设置
                normal: {
                  show: true,             // 单个拐点文本的样式设置。[ default: false ]
                  position: 'top',        // 标签的位置。[ default: top ]
                  distance: 5,            // 距离图形元素的距离。当 position 为字符描述值（如 'top'、'insideRight'）时候有效。[ default: 5 ]
                  color: 'rgb(255,253,113)',          // 文字的颜色。如果设置为 'auto'，则为视觉映射得到的颜色，如系列色。[ default: "#fff" ]
                  fontSize: 14,           // 文字的字体大小
                  // fontFamily:"qingning",
                  opacity: 4,
                  width:3,
                  formatter:function(params) {
                    return params.value;
                  }
                }
              },
              itemStyle: {                // 单个拐点标志的样式设置。
                normal: {
                  borderColor: 'rgb(255,253,113)',       // 拐点的描边颜色。[ default: '#000' ]
                  borderWidth: 3,                        // 拐点的描边宽度，默认不描边。[ default: 0 ]
                }
              },
              lineStyle: {                // 单项线条样式。
                normal: {
                  opacity: 1   ,
                  width:4, // 图形透明度
                  color: 'rgb(255,253,113)',
                }
              },
              areaStyle: {                // 单项区域填充样式
                normal: {
                  color: 'rgba(255,253,113,0.1)'       // 填充的颜色。[ default: "#000" ]
                }
              }
            }, {
              name: '产后抑郁',
              value: [30, 3, 7, 365,30, 365],
              symbol: 'circle',
              symbolSize: 5,
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  distance: 5,
                  color: 'rgba(179,228,161,1)',
                  fontSize: 14,
                  opacity: 4,
                  // fontFamily:"qingning",
                  formatter:function(params) {
                    return params.value;
                  }
                }
              },
              itemStyle: {
                normal: {
                  borderColor: 'rgba(179,228,161,1)',
                  borderWidth: 3,
                }
              },
              lineStyle: {
                normal: {
                  opacity: 1,
                  width: 4,
                  color: 'rgba(179,228,161,1)'
                }
              },
              areaStyle: {
                normal: {
                  color: 'rgba(179,228,161,0.1)'
                }
              }
            },{
              name: '产后精神病',
              value: [4, 5, 5, 42, 28, 120],
              symbol: 'circle',
              symbolSize: 5,
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  distance: 5,
                  color: 'rgba(0,255,247,1)',
                  fontSize: 14,
                  opacity: 4,
                  // fontFamily:"qingning",
                  formatter:function(params) {
                    return params.value;
                  }
                }
              },
              itemStyle: {
                normal: {
                  borderColor: 'rgba(0,255,247,1)',
                  borderWidth: 3,
                }
              },
              lineStyle: {
                normal: {
                  opacity: 1,
                  width: 4,
                  color: 'rgba(0,255,247,1)',
                }
              },
              areaStyle: {
                normal: {
                  color: 'rgba(0,255,247,0.1)'
                }
              }
            }]
          }
        ]
      }
      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
      getchart.on('click',function (param){
        console.log(param.data);
        that.$emit('radar_clicked',param.data.name);
      })
    }
  }
}
</script>

<style scoped>

</style>
