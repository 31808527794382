<template>
<div id="discUsage" :style="{width: '80vw', height: '70vh'}"></div>
</template>

<script>
import * as echarts from 'echarts'
// import axios from 'axios'
export default {
  name: "discUsage",
  data() {
    return {
      data: [
        {
          name: 'nodeA',
          value: 10,
          children: [
            {
              name: 'nodeAa',
              value: 4
            },
            {
              name: 'nodeAb',
              value: 6
            }
          ]
        },
        {
          name: 'nodeB',
          value: 20,
          children: [
            {
              name: 'nodeBa',
              value: 20,
              children: [
                {
                  name: 'nodeBa1',
                  value: 20
                }
              ]
            }
          ]
        }
      ]
    }
  },
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart() {

      let getchart = echarts.init(document.getElementById('discUsage'));
      // const lineStyle = {
      //   width: 1,
      //   opacity: 0.5
      // };
      var img = [
        require('../../assets/baby/bz.png'),
        require('../../assets/baby/yehl.png'),
        require('../../assets/baby/cjbz.png'),
        require('../../assets/baby/tj.png'),
        require('../../assets/baby/nbz.png'),
        require('../../assets/baby/yenx.png'),
        require('../../assets/baby/lzj.png'),
        require('../../assets/baby/fb.png'),
        require('../../assets/baby/yjj.png'),
        require('../../assets/baby/tf.png'),
        require('../../assets/baby/xlj.png'),
      ];
      var option = {
        title:{
          // text:'婴儿疾病预防',
          // x:'center',
          // textStyle:{
          //   fontSize: 25,
          //   fontFamily: '华文宋体'
          // }
        },
        tooltip: {
          show: true,
          padding: [16, 20],
          backgroundColor: 'rgba(0,0,0,0.6)',
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            color: '#fff',
            lineHeight: 14
          }
        },
        series: [
          {
            label:{
              fontSize:20,
              formatter: function (params) {
                console.log(params);
                var newParamsName = "";
                var paramsNameNumber = params.data.name.length;
                var provideNumber = 10;  //一行显示几个字
                if (paramsNameNumber > 25) provideNumber = 20
                else provideNumber = 10
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;
                    if (p == rowNumber - 1) {
                      tempStr = params.data.name.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.data.name.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr;
                  }

                } else {
                  newParamsName = params.data.name;
                }
                return newParamsName
              },
            },
            name: 'option',
            type: 'treemap',
            visibleMin: 500,
            leafDepth: 1,
            fontSize: 50,
            lineHeight: 55,
            data:[
              // {
              //   name:'婴儿护理',
              //   value:300,
              //   children:[
                  {
                    name:'婴儿身体护理',
                    value:250,

                    label:{
                      width: 600,
                      height: 400,
                      lineHeight: 400,
                      align:'center',
                      // borderWidth: 0.5,  // 边框宽度
                      // borderRadius: 20,  // 边框圆角
                      // borderColor: "#B034FF",   // 文字块的边框色
                      backgroundColor: '#f4b586',
                      rich: {
                        img1: {
                          backgroundColor: {
                            image: img[1],
                          },
                          backgroundSize:'contain',
                          backgroundPosition:'center',
                          height: 40
                        }
                      },
                      formatter: function (param) {
                        var res = "";
                        res += '{img1|}' + param.name;
                        return res;
                      },
                    },
                    children:[
                      {
                        name:'头部',
                        value:22,
                        children:[
                          {
                            name:'头发',
                            value:5.5,
                            children:[
                              {
                                name:'护理',
                                value:5.5,
                                children:[
                                  {
                                    name:'1、洗头 \n 6个月前最好每天给宝宝洗1次头，天气热时，多洗1次；6个月后改成2～3天洗1次。 \n 2、勤梳头 \n 3、营养均衡 \n 保证牛奶、瘦肉、鱼、蛋、虾、豆制品、水果和胡萝卜等各种食物的摄入与搭配 \n 4、睡眠充足 \n 1～3个月时每天保证睡眠16～18个小时；4～6个月时每天保证睡眠15～16个小时；7～9个月时每天保证睡眠14～15个小时；10个月以上每天保证睡眠10～13个小时 \n 5、多晒太阳',
                                    value:5.5,
                                  }
                                ]
                              },
                            ]
                          },
                          {
                            name:'理发',
                            value:5.5,
                            children:[
                              {
                                name:'当宝宝长到三个月的时候进行理发，但要避免落根式的剃头。如果宝宝头发稀薄，还可以再等几个月 \n 至于年龄较大的宝宝，可安排两个月剪一次头，更有利于宝宝头发的生长',
                                value:5.5,
                              }
                            ]
                          },
                          {
                            name:'抚摸',
                            value:5.5,
                            children:[
                              {
                                name:'头部抚触能够舒缓宝宝的脸部紧绷状态。可以取适量的婴儿润肤乳液，从宝宝的前额中心开始往外推压，然后是眉心眼窝，下巴和人中，动作一定要轻',
                                value:5.5,
                              }
                            ]
                          },
                          {
                            name:'乳痂',
                            value:5.5,
                            children:[
                              {
                                name:'1、坚持头部清洁护理 \n 2、调整母婴饮食习惯 \n 3、及时修剪头发',
                                value:5.5,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name:'鼻子',
                        value:22,
                        children:[
                          {
                            name:'1、应该竖直抱起宝宝，避免宝宝鼻子不通气，口又呼吸不畅 \n 2、宝宝睡觉时小枕头不宜过低 \n 3、注意室内通风换气，控制室内温度和湿度，保持在适中的范围',
                            value:22,
                          }
                        ]
                      },
                      {
                        name:'口腔',
                        value:22,
                        children:[
                          {
                            name:'牙齿',
                            value:11,
                            children:[
                              {
                                name:'1、实现合理喂养，及时补充婴儿辅食，如饼干、馒头、蔬菜、水果等 \n 2、多沐浴阳光 \n 3、可以适当使用磨牙棒提高宝宝咀嚼能力',
                                value:11,
                              }
                            ]
                          },
                          {
                            name:'舌苔',
                            value:11,
                            children:[
                              {
                                name:'1、在舌苔白厚的时候，饮食就要清淡一些，要多吃些新鲜的蔬果 \n 2、养成良好生活习惯，调整作息时间 \n 3、帮助宝宝保持乐观向上的情绪，避免抑郁、愤怒等情绪的出现',
                                value:11,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name:'身体',
                        value:22,
                        children:[
                          {
                            name:'抚摸',
                            value:12,
                            children:[
                              {
                                name:'胸部',
                                value:3,
                                children:[
                                  {
                                    name:'胸部抚触能够顺畅宝宝的呼吸循环。妈妈可以把双手放在宝宝的两侧肋缘，一只手向上滑，一直到婴儿的肩膀位置，然后再复原',
                                    value:3,
                                  },
                                ]
                              },
                              {
                                name:'手部',
                                value:3,
                                children:[
                                  {
                                    name:'手部抚触能够增加宝宝的灵活反应。让宝宝的双手呈下垂的状态，用一只手捏住宝宝的胳膊从上臂一直到手腕，轻轻的你挤捏，并且轻揉手腕',
                                    value:3,
                                  },
                                ]
                              },
                              {
                                name:'腹部',
                                value:3,
                                children:[
                                  {
                                    name:'腹部抚触，有助于宝宝肠胃的活动。妈妈们可以按照顺时针的方向按摩宝宝的腹部，但是在宝宝的脐痂没有脱落之前，不要按摩这个区域',
                                    value:3,
                                  },
                                ]
                              },
                              {
                                name:'腿部',
                                value:3,
                                children:[
                                  {
                                    name:'腿部抚触，能够有效的增加宝宝的运动协调功能。可以一直按摩宝贝的大腿、小腿、膝盖一直到脚踝，轻轻的挤捏即可',
                                    value:3,
                                  },
                                ]
                              },
                            ]
                          },
                          {
                            name:'指甲',
                            value:5,
                            children:[
                              {
                                name:'1、宝宝的手指甲l周内应修剪2-3次 \n 2、脚指甲，脚趾甲的生长速度较慢，一般1个月修剪1-2次 \n 3、建议妈妈在宝宝熟睡时进行修剪，熟睡中的宝宝对外界敏感度大大降低，妈妈可以放心地进行修剪工作',
                                value:5,
                              }
                            ]
                          },
                          {
                            name:'洗澡',
                            value:5,
                            children:[
                              {
                                name:'1、刚出生的两周内的婴儿脐部还没有完全恢复，父母洗澡后用浴巾裹住宝宝的全身时，可留出脐部，用酒精棉棒从中间向外清洗脐部，注意保持脐部的干燥和清洁 \n 2、宝宝洗澡之后，通常在宝宝的皮肤褶皱处，可以替宝宝扑上爽身粉，如果发现宝宝小屁屁有发红的迹象，应该给发红处擦上屁屁霜，防止发生尿布疹 \n 3、如果洗完澡之后感觉宝宝脸部皮肤干燥，可以在宝宝脸上涂少量的润肤霜 \n 4、在洗完澡后只用浴巾包好宝宝，然后将宝宝放到房间里的床上，脱光衣服，给宝宝做按摩动作，可以适当使用一些精油对宝宝进行按摩',
                                value:5,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name:'私处',
                        value:22,
                        children:[
                          {
                            name:'男宝宝',
                            value:11,
                            children:[
                              {
                                name:'1、 用手把阴茎扶直，轻轻擦拭根部和里面容易藏污纳垢的地方，但不要太用力。可以把小毛巾叠成小方块，然后用折叠的边缘横着擦拭 \n 2、阴囊表面的皱褶里也是很容易聚集污垢的，妈妈可以用手指将皱褶展开后擦拭，等小鸡鸡完全晾干后再换上干净、透气的尿布 \n 3、帮男婴儿穿戴纸尿裤的时候，注意把小鸡鸡向下压，使之伏贴在阴囊上。这样做，一是为了不让宝宝尿尿的时候冲上尿，弄湿衣服，另外，也可以帮助宝宝的阴茎保持自然下垂的状态，避免将来影响穿衣的美观',
                                value:11,
                              }
                            ]
                          },
                          {
                            name:'女宝宝',
                            value:11,
                            children:[
                              {
                                name:'1、给孩子清洗外阴的盆和毛巾一定要专用，不应再有其他用途。最好用脱脂棉、棉签或柔软纱布浸透水给宝宝擦拭，要比用毛巾好 \n 2、使用的盆最好为金属质地，以便用其加热洗涤用水，应将毛巾放入水中，将水加热至沸腾，晾凉至40℃左右再使用。不要一半热水一半凉水。这样做能将自来水、毛巾和水盆上的杂菌彻底杀灭 \n 3、不要使用肥皂等为女婴清洗外阴，平时便便后用清水就可以，水温也不要过高 \n 4、如果外阴部只是有一点红肿的，在换尿布或洗澡时给她洗干净，通常会自愈。如果出现了分泌物或很不舒服，需要在医生的指导下使用一些带有抗生素的软膏或内服药',
                                value:11,
                              }
                            ]
                          },
                        ]
                      },
                      {
                        name:'屁股',
                        value:22,
                        children:[
                          {
                            name:'尿不湿',
                            value:22,
                            children:[
                              {
                                name:'1、宝宝只有一个月大，一般3-4个小时就要更换一次，并且更换好后要用温水给宝宝擦拭屁屁，这样能避免宝宝患湿疹 \n 2、宝宝5-6个月大，每次更换尿不湿的时间可以间隔更久一些 \n 3、尿不湿的材质要尽量选择有机棉或纯棉尿不湿',
                                value:22,
                              }
                            ]
                          }
                        ]
                      },
                      {
                        name:'脚部',
                        value:22,
                        children:[
                          {
                            name:'脚部按摩',
                            value:22,
                            children:[
                              {
                                name:'（1）用拇指以外的四个手指的指肚绕着宝宝的脚踝抚摩 \n （2）用拇指以外的四个手指的指肚，沿脚跟向脚趾方向，在脚底按摩 \n （3）从小趾开始，依次轻轻转动并拉伸每个脚趾 \n （4）重复上述步骤，按摩宝宝的另一只脚',
                                value:22,
                              }
                            ]
                          }
                        ]
                      },
                    ]
                  },
                  {
                    name:'婴儿病症护理',
                    value:250,

                    label:{
                      width: 600,
                      height: 400,
                      lineHeight: 400,
                      align:'center',
                      textStyle: {
                        // color:"rgba(255,255,255,0)",
                        // display:'none'
                      },
                      // borderWidth: 0.5,  // 边框宽度
                      // borderRadius: 20,  // 边框圆角
                      // borderColor: "#B034FF",   // 文字块的边框色
                      backgroundColor: '#87c9f5',
                      rich: {
                        img1: {
                          backgroundColor: {
                            image: img[0],
                          },
                          backgroundSize:'contain',
                          backgroundPosition:'center',
                          height: 40
                        }
                      },
                      formatter: function (param) {
                        var res = "";
                        res += '{img1|}' + param.name;
                        return res;
                      },
                    },
                    children:[
                      {
                        name:'体检',
                        value:56,
                        label:{
                          backgroundColor: '#87c9f5',
                          width: 800,
                          height: 400,
                          lineHeight: 400,
                          align:'center',
                          rich: {
                            img1: {
                              backgroundColor: {
                                image: img[3],
                              },
                              backgroundSize:'contain',
                              backgroundPosition:'center',
                              height: 40
                            }
                          },
                          formatter: function (param) {
                            var res = "";
                            res += '{img1|}' + param.name;
                            return res;
                          },
                        },

                        children:[
                          {
                            name:'宝宝在1个半月、3个月、6个月、9个月、12个月的时候各做一次体检',
                            value:56,
                            label:{
                              backgroundColor: '#87c9f5',
                            },
                          }
                        ]
                      },
                      {
                        name:'常见病症',
                        value:90,
                        label:{
                          width: 400,
                          height: 400,
                          lineHeight: 400,
                          align:'center',
                          // borderWidth: 0.5,  // 边框宽度
                          // borderRadius: 20,  // 边框圆角
                          // borderColor: "#B034FF",   // 文字块的边框色
                          backgroundColor: '#5eb8f5',
                          rich: {
                            img1: {
                              backgroundColor: {
                                image: img[2],
                              },
                              backgroundSize:'contain',
                              backgroundPosition:'center',
                              height: 40
                            }
                          },
                          formatter: function (param) {
                            var res = "";
                            res += '{img1|}' + param.name;
                            return res;
                          },
                        },
                        children:[
                          {
                            name:'烂嘴角',
                            value:20,
                            label:{
                              backgroundColor: '#5aa8de',
                              width: 200,
                              height: 230,
                              lineHeight: 230,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[6],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'成因',
                                value:7,
                                children:[
                                  {
                                    name:'1、宝宝饮食过于精细、偏食 \n 2、气候干燥，皮肤易干裂 \n 3、经常舔嘴角',
                                    value:7,
                                  },
                                ]
                              },
                              {
                                name:'防治',
                                value:6,
                                children:[
                                  {
                                    name:'一、补充B族维生素 \n 二、注意膳食平衡 \n 三、重视口腔的清洁卫生 \n 四、餐后和睡前涂抹润唇膏',
                                    value:6,
                                  },
                                ]
                              }
                            ]
                          },
                          {
                            name:'婴儿奶廯',
                            value:20,
                            label:{
                              backgroundColor: '#3688bf',
                              width: 200,
                              height: 200,
                              lineHeight: 200,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[5],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'症状',
                                value:5,
                                children:[
                                  {
                                    name:'脂肪粒、脸上红红的、皮肤干、下颚退皮',
                                    value:5,
                                  },
                                ]
                              },
                              {
                                name:'成因',
                                value:4,
                                children:[
                                  {
                                    name:'1、可能是宝宝过敏了 \n 2、可能是宝宝太热了、穿多了 \n 3、可能是先天因素作祟 ',
                                    value:4,
                                  },
                                ]
                              },
                              {
                                name:'防治',
                                value:4,
                                children:[
                                  {
                                    name:'1、先确定是否跟母乳、膳食、牛奶有关 \n 2、谨慎用药 \n 3、清洗护理 \n  4、避免宝宝搔抓 \n 5、衣物宽大点好',
                                    value:4,
                                  },
                                ]
                              }
                            ]
                          },
                          {
                            name:'常见眼疾',
                            value:15,
                            label:{
                              backgroundColor: '#72bcee',
                              width: 150,
                              height: 300,
                              lineHeight: 300,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[8],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 20
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'症状',
                                value:7,
                                children:[
                                  {
                                    name:'1、流泪，宝宝眼睛自然流出泪水 \n 2、怕光，宝宝的眼睛不愿睁开 \n 3、发红，宝宝眼睛的白眼球及眼皮发红，并伴有黄白色分泌物 \n 4、无神，宝宝的眼神黯淡 \n 5、睑垂，如果宝宝眼睛下垂，应及时带宝宝就医 \n 6、频繁眨眼',
                                    value:7,
                                  },
                                ]
                              },
                              {
                                name:'预防',
                                value:6,
                                children:[
                                  {
                                    name:'1、讲究眼部卫生 \n 2、少接触显示器 \n 3、正确喂养姿势 \n 4、减少噪音 \n 5、色彩训练',
                                    value:6,
                                  },
                                ]
                              }
                            ]
                          },
                          {
                            name:'皮包垢',
                            value:10,
                            label:{
                              backgroundColor: '#7dd7f3',
                              width: 200,
                              height: 100,
                              lineHeight: 100,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[10],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                              // lineHeight:50,
                              // height:200,
                            },
                            children:[
                              {
                                name:'清洗',
                                value:13,
                                children:[
                                  {
                                    name:'1、用温水泡盆进行清洗，水温要控制在38～40摄氏度 \n 2、适当涂抹药膏 \n 3、生理性包茎与环切手术',
                                    value:13,
                                  },
                                ]
                              }
                            ]
                          },
                          {
                            name:'腹胀',
                            value:15,
                            label:{
                              backgroundColor: '#63b9e7',
                              width: 200,
                              height: 170,
                              lineHeight: 170,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[7],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'成因',
                                value:7,
                                children:[
                                  {
                                    name:'1、宝宝吞食的空气过多 \n 2、宝宝消化不良 \n 3、宝宝肠胃蠕动障碍 \n 4、气管病变造成的胀气',
                                    value:7,
                                  },
                                ]
                              },
                              {
                                name:'按摩法',
                                value:6,
                                children:[
                                  {
                                    name:'(1)患儿仰卧位，家长用中指端揉膻中穴50次，然后用掌根直推膻中50次，再分腹阴阳30次 \n (2)患儿仰卧，摩中脘5分钟，点揉水分穴1分钟 \n (3)按揉足三里穴2分钟',
                                    value:6,
                                  },
                                ]
                              },
                            ]
                          },
                          {
                            name:'枕秃',
                            value:10,
                            label:{
                              backgroundColor: '#7ec0ec',
                              width: 120,
                              height: 300,
                              lineHeight: 300,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[9],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'防治',
                                value:13,
                                children:[
                                  {
                                    name:'1、给宝宝选择透气、高度适中、柔软适中的枕头 \n 2、调整睡觉合适的温度 \n 3、给宝宝补充钙质 \n 4、午10点以后或下午2点以后让宝宝晒晒太阳',
                                    value:13,
                                  },
                                ]
                              }
                            ]
                          },
                          {
                            name:'尿布疹',
                            value:20,
                            label:{
                              backgroundColor: '#237fbd',
                              width: 200,
                              height: 200,
                              lineHeight: 200,
                              align:'center',
                              rich: {
                                img1: {
                                  backgroundColor: {
                                    image: img[4],
                                  },
                                  backgroundSize:'contain',
                                  backgroundPosition:'center',
                                  height: 40
                                }
                              },
                              formatter: function (param) {
                                var res = "";
                                res += '{img1|}' + param.name;
                                return res;
                              },
                            },
                            children:[
                              {
                                name:'轻度红臀（宝宝的小屁屁仅仅是皮肤发红）',
                                value:5,
                                label:{
                                  backgroundColor: '#559fd0',
                                  lineHeight:50,
                                  height:200,
                                  textAlign:'left',
                                  textStyle: {
                                    lineHeight:50,
                                    align:'left'
                                  }
                                },
                                children:[
                                  {
                                    name:'保护好宝宝的臀部皮肤，每次大、小便后注意用温水洗净臀部，并用柔软的小毛巾吸干（不要用纸擦），然后涂上一薄层煮沸消毒（须放凉）的植物油或清鱼肝油保护皮肤',
                                    value:5,
                                    label:{
                                      backgroundColor: '#559fd0',
                                      lineHeight:50,
                                      height:200,
                                      textAlign:'left',
                                      textStyle: {
                                        lineHeight:50,
                                        align:'left'
                                      }
                                    },
                                  },
                                ]
                              },
                              {
                                name:'中度尿布疹（有些许红疹）',
                                value:4,
                                label:{
                                  backgroundColor: '#63b9e7',
                                  lineHeight:50,
                                  height:200,
                                  textAlign:'left',
                                  textStyle: {
                                    lineHeight:50,
                                    align:'left'
                                  }
                                },
                                children:[
                                  {
                                    name:'先擦上乳液隔离，并且要勤于更换尿布。由于宝宝皮肤的恢复能力较好，家长可以先观察1天或半天，如果红疹有所消退，就不用担心了',
                                    value:4,
                                  },
                                ]
                              },
                              {
                                name:'重度尿布疹（不仅有红疹，还出现了水疱）',
                                value:4,
                                label:{
                                  backgroundColor: '#5aa8de',
                                  lineHeight:50,
                                  height:200,
                                  textAlign:'left',
                                  textStyle: {
                                    lineHeight:50,
                                    align:'left',
                                  }
                                },
                                children:[
                                  {
                                    name:'用红汞（红药水）局部涂搽，也可用红霉素眼膏或者3%～5%鞣酸软膏；如皮疹已经溃破，可涂以氧化锌油膏',
                                    value:4,
                                  },
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
            ]

            ,
            levels: [
              {
                itemStyle: {
                  // borderColor: '#3c898b',
                  borderWidth: 0,
                  gapWidth: 0,
                  border:'none'
                }
              },
              {
                itemStyle: {
                  roam: 'false',
                  // borderColorSaturation: 0.7,
                  // gapWidth: 2,
                  // borderWidth: 2
                }
              },
              {
                itemStyle: {
                  roam: 'false',
                  // borderColorSaturation: 0.6,
                  // gapWidth: 1
                }
              },
              {
                //colorSaturation: [0.3, 0.5]
              }
            ]
          }
        ],
      };
      const dataArr = [];
      for (let i = 0; i < 10; i++) {
        const obj = {
          name: i,
          value: 20,
          label: {
            show: true,
            verticalAlign: 'top',
            align: 'center',
            formatter: function (params) {
              return ['{a|第n个}', '{b|数字%}'].join('\n');
            },
            rich: {
              a: {
                color: '#333',
                fontSize: 12,
                lineHeight: 17,
                align: 'center'
              },
              b: {
                color: '#d0021b',
                fontSize: 12,
                lineHeight: 17,
                align: 'center'
              }
            }
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fff',
            color: 'rgba(242,71,36,0.1)'
          }
        };
        dataArr.push(obj);
      }
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
