<template>
  <div id="point2" :style="{width: '100%', height: '100%',margin: '10px auto'}"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "points2",
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {

      let getchart = echarts.init(document.getElementById('point2'));
      var fw=[
        {
          bz:82,xx:76,sx:89
        },
        {
          bz:85,xx:80,sx:91
        },
        {
          bz:87,xx:82,sx:94
        },
        {
          bz:89,xx:84,sx:95
        },
        {
          bz:92,xx:86,sx:98
        },
        {
          bz:94,xx:89,sx:100
        }
      ];
      var option = {
        title: [
          {
            text: '孕妈腹围标准表',
            textStyle:{
              color:'#fff',
              fontSize:16,
              fontFamily:'qingning'
            }
          }
        ],
        polar: {
          radius: [30, '80%']
        },
        angleAxis: {
          max: 110,
          startAngle: 75,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        radiusAxis: {
          type: 'category',
          data: ['5', '6', '7', '8', '9', '10'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var result =params.marker + '孕' + params.name+'月 '+  '标准：'+ params.value+'cm ；范围：'+fw[params.dataIndex].xx+'~'+fw[params.dataIndex].sx+'cm'
            // console.log(params);
            return result
          }
        },
        series: {
          type: 'bar',
          data: [82, 85, 87, 89, 92, 94],
          coordinateSystem: 'polar',
          label: {
            show: true,
            position: 'middle',
            formatter: '{b}: {c}',
            color:'#fff'
          },
          itemStyle:{
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#f8c181' },
              { offset: 1, color: '#fefd70' }
            ])
          }
        }
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
