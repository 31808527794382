<template>
  <div id="pie" :style="{width: '100%', height: '100%',margin: '10px auto'}"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "pie",
  mounted() {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    this.initChart()
  },
  methods: {

    initChart: function () {

      let getchart = echarts.init(document.getElementById('pie'));
      var option = {
        // backgroundColor: '#2c343c',
        title: {
          text: '孕期各月份体重增长数',
          left: 'center',
          top: 15,
          textStyle: {
            color: '#fff',
            fontFamily:'qingning',
            marginBottom:15
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var result =params.marker + params.name+' '+  params.value+'kg 左右'
            return result
          }
        },
        visualMap: {
          show: false,
          min: 0,
          max: 2,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [
          {
            name: '孕期各月份体重增长数',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: [
              { value: 0.50, name: '孕一月' },
              { value: 0.50, name: '孕二月' },
              { value: 0.60, name: '孕三月' },
              { value: 1.20, name: '孕四月' },
              { value: 1.50, name: '孕五月' },
              { value: 1.70, name: '孕六月' },
              { value: 1.70, name: '孕七月' },
              { value: 1.20, name: '孕八月' },
              { value: 1.60, name: '孕九月' },
              { value: 1.30, name: '孕十月' }
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: 'radius',
            label: {
              color: 'rgba(255, 255, 255, 0.5)'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.5)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            itemStyle: {
              color: '#ffcf53',
              shadowBlur: 200,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      };
      getchart.setOption(option);
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    }
  }
}
</script>

<style scoped>

</style>
